.loading-spinner {
    position: relative;
    width: 100px;
    height: 100px;
    border: 1px solid $c-brand-blue--light;
    border-radius: 100px;
    border-bottom: 1px solid $c-white;
    animation: rotate 1s infinite linear;
    margin: auto;
    display: none;

    &::before {
        content: "";
        position: absolute;
        width: 80%;
        height: 80%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 1px solid $c-brand-blue--light;
        border-radius: 100px;
    }
}

@keyframes rotate {
    from {
        transform: rotate(360deg);
    }
    to {
        transform: rotate(0deg);
    }
}

#rooster_loadmore {
    text-transform: uppercase;
    color: $c-brand-blue--light;
    letter-spacing: 2px;
    margin-top: 16px;
    border: none;
    background-color: transparent;
    transition: 0.4s;
    cursor: pointer;

    &::after {
        content: url("../img/arrow-light.svg");
        bottom: 0;
        margin-left: 0.5em;
        opacity: 1;
        transition: 0.4s;
        display: inline-block;
        transform: rotate(90deg);
    }

    &:hover {
        letter-spacing: 8px;
        border-bottom: none;
    }
}

.my-repeater-show-more-link {
    display: flex;
    align-items: center;
    justify-content: center;
}

//Mixins

// Section-width
//
// Predefined Section horizontal width using template columns.
// <pre class="hljs"><code>@include grid-mixins;</code></pre>
//
// Weight: 1
//
// Styleguide Mixins.sections

@mixin section-width($section-width) {
    grid-template-columns: 1fr minmax(auto, $section-width) 1fr;
}

// sass-lint:disable no-important

// Hide
//
// The HTML5 Boilerplate visually hidden &ndash; with a couple of tweaks &ndash; hides things in an accessible way. Doesn't take any arguments
// <pre class="hljs"><code>@include hide;</code></pre>
//
// Weight: 1
//
// Styleguide Mixins.hide
@mixin hide {
    position: absolute !important;
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: polygon(0 0, 0 0, 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    white-space: nowrap;
}

// Unhide
//
// This undoes the <code>hide</code> function. There's an important on the position static, so if the unhidden item is being positioned in another way that will need an `!important`
// <pre class="hljs"><code>@include unhide;</code></pre>
//
// Weight: 2
//
// Styleguide Mixins.unhide
@mixin unhide($pos: static) {
    // Reset the position, can be changed if it does need to be absolute
    position: $pos !important;
    border: 0;
    clip: auto !important;
    clip-path: none;
    height: auto;
    width: auto;
    margin: inherit;
    overflow: visible;
    padding: inherit;
    white-space: normal;
}

// Creating a clamp() fallback function in Sass/SCS
// https://www.bronco.co.uk/our-ideas/creating-a-clamp-fallback-function-in-sass-scss/
/* Simple one value */
// @include clamp('font-size',12px,3vw,24px);
// Outputs
// font-size:24px; font-size:min(max(12px, 3vw), 24px); font-size:clamp(12px,3vw,24px);

@mixin clamp(
    $property,
    $min-size,
    $scaler,
    $max-size,
    $min-size-left: false,
    $scaler-left: false,
    $max-size-left: false
) {
    @if $min-size-left == false {
        #{$property}: $max-size;
        #{$property}: #{"min(max(#{$min-size}, #{$scaler}), #{$max-size})"};
        #{$property}: clamp($min-size, $scaler, $max-size);
    } @else if $min-size-left == 0 or $min-size-left == auto {
        #{$property}: $max-size $min-size-left;
        #{$property}: #{"min(max(#{$min-size}, #{$scaler}), #{$max-size})"}
            $min-size-left;
        #{$property}: clamp($min-size, $scaler, $max-size) $min-size-left;
    } @else {
        #{$property}: $max-size $min-size-left;
        #{$property}: #{"min(max(#{$min-size}, #{$scaler}), #{$max-size})"}
            #{"min(max(#{$min-size-left}, #{$scaler-left}), #{$max-size-left})"};
        #{$property}: clamp($min-size, $scaler, $max-size)
            clamp($min-size-left, $scaler-left, $max-size-left);
    }
}

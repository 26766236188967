.accordion {
  padding-top: calc(min(5.2vw, 100px));
  padding-bottom: calc(min(10.4vw, 200px));

  .section__container {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;

    .ie & {
      margin: 100px 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    @include breakpoint($bp-large) {
      grid-template-columns: 1fr 1fr;
    }
  }

  .image__wrapper {
    padding-bottom: 115.53%;

    .ie & {
      width: 47.5%;
      padding-bottom: 0;
      height: 800px;
    }
  }

  &__header {
    grid-column: 1 / -1;
    margin-bottom: 30px;

    .ie & {
      width: 100%;
    }

    @include breakpoint($bp-medium) {
      margin-bottom: 85px;
    }

    @include breakpoint($bp-large) {
      width: 40%;
    }
  }

  &__wrapper {
    padding-left: 0px;

    .ie & {
      width: 47.5%;
      padding-left: 0px;
    }

    @include breakpoint($bp-large) {
      padding-left: 50px;
    }

    @include breakpoint($bp-xl) {
      padding-left: 100px;
    }

    @include breakpoint($bp-xxl) {
      padding-left: 200px;
    }
  }

  &__title {
    padding-bottom: 30px;
    padding-top: 30px;
    border-bottom: 1px solid $c-brand-blue--light;
  }

  &__heading {
    position: relative;
    padding: 30px 0;
    border-bottom: 1px solid $c-brand-blue--light;
    cursor: pointer;
    user-select: none;
    @include clamp('font-size', 0.937rem, calc(0.8744rem + 0.313vw), 1.25rem);

    h3 {
      max-width: 80%;
    }

    i {
      color: $c-brand-blue--light;
      border: 1px solid $c-brand-blue--light;
      padding: 14px 15px;
      border-radius: 9999px;
      position: absolute;
      right: -10px;
      top: 20px;
      transform: scale(0.5);

      @include breakpoint($bp-large) {
        top: 50%;
        right: 0px;
        transform: translateY(-50%);
      }
    }

    .fa-plus {
      opacity: 0;
      transition: 0.2s;
      .closed & {
        opacity: 1;
      }
    }
  }

  &__text {
    transition: 0.5s;
    transform-origin: top;
    position: relative;
    overflow: hidden;
    max-height: 0;
  }
}

.staff-members {
  padding-top: calc(min(5.2vw, 100px));
  padding-bottom: 0px;

  .heading {
    margin-bottom: 60px;

    .ie & {
      margin-top: 50px;
    }
  }
}

.staff-members__repeater {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(215px, 1fr));
  gap: 65px;

  .ie & {
    display: flex;
    justify-content: space-between;
  }
}

.staff-members__profile {
  .ie & {
    width: 19%;
  }

  .image__wrapper {
    height: auto;
    width: 100%;
    padding-bottom: 100%;
    margin-bottom: 40px;

    .ie & {
      padding-bottom: 0;
      height: 200px;
    }
  }
}

.staff-members__info {
  margin-bottom: 25px;

  > * {
    margin-bottom: 10px;
    width: 100%;
  }
}

.staff-members__qualifications {
  li {
    margin-bottom: 10px;
    width: 100%;
  }
}

a,
a:visited {
    color: $c-text;

    * {
        transition: 0.3s;
    }

    .section--dark & {
        color: $c-white;
    }

    .scrolled & {
        color: $c-text;
    }

    &:hover * {
        color: $c-brand-green;
    }

    .menu & {
        // &:hover {
        //     border-bottom: 1px solid $c-brand-green;
        // }
    }
}

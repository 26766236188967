.two-columns-text {
    .section__container {
        display: grid;

        .ie & {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        &.even-cols {
            grid-template-columns: 1fr;
            column-gap: 100px;

            .ie & {
                > * {
                    width: 47.5%;
                }
            }

            @include breakpoint($bp-medium) {
                grid-template-columns: 1fr 1fr;
            }
        }

        .decorative-block--blue {
            display: none;

            @include breakpoint($bp-medium) {
                display: block;
            }
        }

        &.small-col-big-col {
            grid-template-columns: 1fr;
            column-gap: 180px;

            .ie & {
                .content-left {
                    width: 47.5%;
                }

                .content-right {
                    width: 47.5%;
                }
            }

            @include breakpoint($bp-medium) {
                grid-template-columns: 1fr 1.55fr;
            }
        }

        .heading {
            grid-column: 1/-1;
            position: relative;
            padding-bottom: 45px;
            margin-bottom: 45px;

            .ie & {
                width: 100%;
            }

            @include breakpoint($bp-medium) {
                width: 45%;
            }

            > * {
                margin-bottom: 10px;
            }
        }

        hr {
            width: 120px;
            height: 1px;
            position: absolute;
            left: 0;
            bottom: 0;
            margin: 0;
            background-color: $c-brand-blue--light;
            border: none;
        }
    }

    .content {
        > * {
            margin-bottom: 30px;

            .ie & {
                width: 100%;
            }
        }

        &-left {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
    }
}

.post-excerpts {
    padding-top: calc(min(5.2vw, 100px));

    h1 {
        margin-bottom: 80px;
    }
}

.post-excerpt {
    &__repeater {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
        grid-template-rows: auto;
        column-gap: 70px;
        row-gap: 95px;

        .ie & {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }

    &__item {
        height: auto;
        position: relative;

        .ie & {
            width: 24%;
            margin: 30px 0;
        }

        &:hover {
            .image {
                transform: scale(1.1);
            }

            .button {
                border-color: $c-brand-green;
                color: $c-brand-green;
            }
        }

        .image {
            transition: 0.3s;
        }

        .image__wrapper {
            padding-bottom: 100%;
            height: auto;
        }

        .overlay-link {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 20px;

        .ie & {
            > * {
                width: 100%;
            }
        }

        p {
            font-size: 0.9375em;
        }

        > * {
            margin-top: 20px;
        }

        .posted-date {
            color: $c-brand-blue--light;
        }
    }
}

.events {
    padding-top: calc(min(5.2vw, 100px));
    .events__repeater {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        justify-content: center;
        grid-auto-flow: row;
        gap: 20px;

        .ie & {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: flex-start;
        }

        @include breakpoint($bp-large) {
            grid-template-columns: repeat(2, 1fr);
        }

        @include breakpoint($bp-xxl) {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    &__header {
        position: relative;
        margin-bottom: 45px;

        @include breakpoint($bp-large) {
            width: 50%;
        }
    }

    &__item {
        position: relative;
        width: 100%;
        overflow: hidden;
        padding-bottom: auto;
        height: 650px;

        .ie & {
            width: 32%;
            margin-bottom: 30px;
            height: 600px;

            padding-bottom: 0;
        }

        @include breakpoint($bp-medium) {
            padding-bottom: 100%;
        }

        &:hover {
            .events__content-booking {
                max-height: 300px;
                opacity: 1;
            }
        }

        .touch-true & {
            .events__content-booking {
                max-height: 300px;
                opacity: 1;
            }
        }
    }

    &__content {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        height: auto;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 40px;
        background-color: rgba($c-black, 0.9);
        text-align: center;

        .ie & {
            * > {
                width: 100%;
            }
        }

        h2,
        h3,
        h4,
        h5 {
            color: $c-white;
        }

        &-booking {
            transition: 0.5s;
            max-height: 0;
            opacity: 0;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            .ie & {
                width: 100%;
                max-height: 300px;
            }

            .button--closed {
                pointer-events: none;
                opacity: 0.8;
            }
        }

        &-title {
            margin: 5px 0 20px 0;
            letter-spacing: 1px;

            .ie & {
                width: 100%;
            }
        }

        &-subtext {
            margin: 15px 0;
            font-family: $f-secondary;
            text-transform: uppercase;

            .ie & {
                width: 100%;
            }
        }

        &-description {
            margin: 15px 0;

            .ie & {
                width: 100%;
            }
        }

        .date-time {
            border: 1px solid $c-brand-green;
            padding: 10px;
            margin: 5px 0;

            .ie & {
                width: 100%;
            }
        }

        .ticket__text {
            margin-bottom: 20px;
            color: $c-brand-green;
            font-weight: 600;

            .ie & {
                width: 100%;
            }
        }
    }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
            to top,
            rgba(#000, 0.8),
            rgba(#000, 0) 50%,
            rgba(#000, 0.8) 100%
        );
    }

    .image {
        &__wrapper {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
        }
    }
}

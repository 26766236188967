.picture-links {
    .section__container {
        display: grid;
        grid-template-columns: repeat(auto-fit, calc(100%));
        justify-content: center;
        grid-auto-flow: row;
        gap: 20px;

        .ie & {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }

        @include breakpoint($bp-medium) {
            grid-template-columns: repeat(auto-fit, calc(33% - 10px));
        }
    }

    .heading {
        grid-column: 1/-1;
        position: relative;
        margin-bottom: 45px;
        text-align: center;

        .ie & {
            width: 100%;
        }
    }

    .foot {
        grid-column: 1/-1;
        display: flex;
        justify-content: center;
        margin-top: 40px;

        .ie & {
            width: 100%;
        }
    }

    &__item {
        position: relative;

        .ie & {
            width: 32%;
        }

        h3.title {
            position: absolute;
            left: 20px;
            bottom: 20px;
            padding-right: 20px;
            @include clamp(
                "font-size",
                1.25em,
                calc(1.125rem + 0.625vw),
                1.875rem
            );
            color: white;

            @include breakpoint($bp-medium) {
                left: 35px;
                bottom: 40px;
            }
        }
    }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
            to top,
            rgba(#000, 1),
            rgba(#000, 0.7) 50%,
            rgba(#000, 0) 100%
        );
    }

    .image {
        object-position: 50%;
        transition: 0.4s;

        &:hover {
            transform: scale(1.1);
        }

        &__wrapper {
            padding-bottom: 87%;
        }
    }
}

.videos {
    &__repeater {
        display: grid;
        grid-template-columns: 1fr;
        column-gap: 35px;
        row-gap: 15px;
        grid-template-rows: 1fr;

        .ie & {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        @include breakpoint($bp-xl) {
            grid-template-columns: 1fr 1fr;
        }
    }

    &__container {
        position: relative;
        overflow: hidden;
        max-width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        .ie & {
            width: 47.5%;
            margin: 30px 0;
        }

        h3 {
            margin-top: 20px;
        }

        iframe,
        object,
        embed {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            min-height: 250px;
        }
    }

    &__wrapper {
        position: relative;
        min-height: 250px;
        max-height: 450px;

        @include breakpoint($bp-large) {
            height: 495px;
        }
    }

    .heading {
        margin-bottom: 50px;

        @include breakpoint($bp-large) {
            width: 45%;
        }

        p {
            color: $c-brand-blue--light;
        }
    }
}

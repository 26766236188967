.decorative-block {
  pointer-events: none;
  &--blue {
    position: absolute;
    bottom: -45px;
    right: 20px;
    height: 90px;
    width: 100%;
    border-right: 1px solid $c-brand-blue--light;

    @include breakpoint($bp-medium) {
      position: relative;
      border-right: none;
      border-left: 1px solid $c-brand-blue--light;
      right: 0;
    }
  }

  &--green {
    bottom: 0;
    left: 0;
    height: 0.7vw;
    min-height: 8px;
    width: 50%;
    max-width: 350px;
    background-color: $c-brand-green;
    position: absolute;

    .flip-layout & {
      left: auto;
      right: 0;
    }
  }
}

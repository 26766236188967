//Variables

//Variables

// Major breakpoints
//
// See the [overview](index.html#breakpoints) for details on how the breakpoints work.
//
// Weight: 4
//
// $bp-vsmall - 24.375em / 390px - iPhone 6/7/8+
// $bp-small - 32.5em / 520px
// $bp-medium - 48em / 768px
// $bp-large - 60em / 960px
// $bp-xl - 80em / 1280px
// $bp-xxl - 100em / 1600px
// $bp-hd - 120em / 1920px
//
// Styleguide Variables.Breakpoints

$bp-vsmall: 24.375em; // 390px - iPhone 12 and larger ones.
$bp-small: 32.5em; // 520px
$bp-medium: 48em; // 768px
$bp-large: 60em; // 960px
$bp-xl: 80em; // 1280px
$bp-xxl: 100em; // 1600px
$bp-hd: 120em; // 1920px

// Pixel breakpoints
//
// Sometimes breakpoints need to be in pixels, normally when using min & max widths at the same time. There are 2 variations, the `-px` which corresponds to the exact pixel size of the breakpoint, and the `-mw` which is 1 pixel less, so useful for max-widths. They should be used like so
// <pre class="hljs"><code>@include breakpoint($bp-small) { /* CSS for bp-small and wider */}
// @include breakpoint($bp-px-medium $bp-mw-large) { /* CSS for only between bp-medium and bp-large, not including the initial pixel of bp-large */ }
// @include breakpoint($bp-large) { /* CSS for bp-large and wider */ }</code></pre>
//
// Weight: 4.1
//
// $bp-mw-vsmall - 389px
// $bp-px-vsmall - 390px
// $bp-mw-small - 519px
// $bp-px-small - 520px
// $bp-mw-medium - 767px
// $bp-px-medium - 768px
// $bp-mw-large - 959px
// $bp-px-large - 960px
// $bp-mw-xl - 1279px
// $bp-px-xl - 1280px
// $bp-mw-xxl - 1599px
// $bp-px-xxl - 1600px
//
// Styleguide Variables.Breakpoints.Pixels

// This formula auto generates the px value so they only need to change in 1 place
$bp-px-vsmall: ($bp-vsmall / 1em) * 16 + 0px;
$bp-px-small: ($bp-small / 1em) * 16 + 0px;
$bp-px-medium: ($bp-medium / 1em) * 16 + 0px;
$bp-px-large: ($bp-large / 1em) * 16 + 0px;
$bp-px-xl: ($bp-xl / 1em) * 16 + 0px;
$bp-px-xxl: ($bp-xxl / 1em) * 16 + 0px;

$bp-mw-vsmall: $bp-px-vsmall - 1;
$bp-mw-small: $bp-px-small - 1;
$bp-mw-medium: $bp-px-medium - 1;
$bp-mw-large: $bp-px-large - 1;
$bp-mw-xl: $bp-px-xl - 1;
$bp-mw-xxl: $bp-px-xxl - 1;

// Z-index
//
// Some z-indices to keep things simple
//
// $z-back:        -1; - Behind the current element
// $z-level:        0; - level with the current element
// $z-one:          1; - just over the element
// $z-low:        100; - generic low level
// $z-high:      1000; - generic higher level
// $z-nav:      10000; - the navigation (assuming it's fixed)
// $z-over-nav: 10010; - over the navigation
// $z-over-all: 20000; - over everything
//
// Weight: 5
//
// Styleguide Variables.Z-index

$z-back: -1;
$z-level: 0;
$z-one: 1;
$z-low: 100;
$z-high: 1000;
$z-nav: 10000;
$z-over-nav: 10010;
$z-over-all: 20000;

// Spacers / Pushers
//
// These are the standard small/medium/large push/padding variables.
//
// $p-small:  15px; - Small, used upto bp-small
// $p-medium: 20px; - Medium, used between bp-small and bp-large
// $p-large:  30px; - Large, used over bp-large
//
// Weight: 6
//
// Styleguide Variables.Pushers

$p-small: 15px;
$p-medium: 20px;
$p-large: 30px;

// Utility colours
//
// Weight: 1
//
// Such as black, white & greys.
//
// Colors:
// $c-black: #000000 - Black
// $c-white: #ffffff - White
// $c-grey-light: #cccccc - Light grey
// $c-grey-dark: #3c3c3b - Dark grey, also default text colour $c-text
// $c-bg-td: #cfebeb - Table alternate row background
//
// Styleguide Variables.Utility-Colours

// Black & white
$c-black: #000;
$c-white: #fff;

// Greys
$c-grey-light: #ccc;
$c-grey-dark: #3c3c3b;

// Backgrounds
$c-bg-td: #cfebeb;

// Brand colours
//
// Weight: 2
//
// On brand colours, these get added to an array so they can be looped with modifiers if needed. Always in the format <code>$c-brand-[name]-[modifier]</code><br>
// **Note:** the example red uses hsl to demonstrate the ease of doing dark/light modiferers of brand colours when the hue stays the same but the saturation/lightness changes
//
// Colors:
// $c-brand-blue: #002953; - Blue
// $c-brand-blue--light: #003D7D; - Light Blue
// $c-brand-green: #39B54A; - Green
//
// Styleguide Variables.Brand-Colours

// TODO replace with actual colours
$c-brand-blue: #002953;
$c-brand-blue--light: #003d7d;
$c-brand-green: #39b54a;

$c-text: #151515;

$c-error: #ef4040;

//Typography

$f-primary: "linotype-didot-headline", serif;
$f-secondary: "gill-sans-nova", sans-serif;

.section--map {
    padding-top: 0;

    .mapouter {
        position: relative;
        text-align: right;
        height: 650px;
        width: 100%;
    }

    .gmap_canvas {
        overflow: hidden;
        background: none !important;
        height: 650px;
        width: 100%;
    }
}

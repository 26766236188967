.literature {
  &__repeater {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
    gap: 65px;
    margin: calc(min(5.2vw, 100px)) 0;

    .ie & {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .file-thumbnail {
      margin-bottom: 25px;
      min-height: 150px;
      width: 100%;
      height: auto;
    }

    .ie & {
      width: 18%;

      margin: 30px 0;

      > * {
        width: 100%;
      }
    }

    h2 {
      @include clamp('font-size', 1.2em, calc(1.19rem + 0.05vw), 1.25em);
      margin-bottom: 25px;
    }

    i {
      @include clamp('font-size', 1.2em, calc(1.17rem + 0.15vw), 1.35em);
    }
  }
}

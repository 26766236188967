.footer {
	background-color: $c-brand-blue;
	padding-top: 30px;
	letter-spacing: 2px;
	text-transform: uppercase;

	* {
		line-height: 1.8em;
	}

	i:before {
		font-size: 20px;
	}

	&__container {
		font-size: 0.75em;
	}

	&__socials {
		padding: 50px 0 25px 0;
		border-bottom: 1px solid $c-white;

		@include breakpoint($bp-large) {
			border-bottom: 1px solid transparent;
		}

		i {
			transition: 0.3s;
		}

		a {
			margin-right: 40px;

			&:hover {
				i {
					color: $c-brand-green;
				}
			}
		}
	}

	&__logo {
		width: 190px;
		justify-self: flex-start;

		.ie & {
			width: auto;
		}

		@include breakpoint($bp-large) {
			justify-self: flex-start;
		}

		@include breakpoint($bp-xl) {
			justify-self: flex-start;
		}

		.logo {
			position: relative;
			width: 190px;
			height: auto;

			.ie & {
				width: auto;
			}

			@include breakpoint($bp-xl) {
				margin-bottom: 0px;
			}

			@include breakpoint($bp-xxl) {
				margin-bottom: 25px;
				width: 238px;
			}
		}
	}

	&__top {
		position: relative;
		display: grid;
		grid-template-columns: 1fr;
		align-items: flex-start;

		.ie & {
			display: flex;
			justify-content: space-between;
			max-height: 106px;
			max-height: var(--hero-height);
		}

		@include breakpoint($bp-large) {
			grid-template-columns: 250px auto 70px;
		}

		&--centre {
			ul#footer-main {
				flex-direction: column;
				justify-content: flex-end;

				@include breakpoint($bp-large) {
					flex-direction: row;
				}

				> li.menu-item {
					margin: 0;

					@include breakpoint($bp-large) {
						margin: 0 10px;
					}

					@include breakpoint($bp-xxl) {
						margin: 0 20px;
					}
				}
			}
		}

		&--right {
			display: flex;
			justify-content: flex-end;
			position: absolute;
			top: 0;
			right: 0;

			@include breakpoint($bp-xl) {
				position: relative;
			}

			a {
				margin-left: 20px;

				i {
					transition: 0.3s;
				}

				&:hover {
					i {
						color: $c-brand-green;
					}
				}
			}
		}
	}

	&__middle {
		display: grid;
		grid-template-columns: auto;
		align-items: flex-end;
		margin-top: 25px;

		.ie & {
			display: flex;
			justify-content: space-between;
		}

		@include breakpoint($bp-medium) {
		}

		@include breakpoint($bp-large) {
			grid-template-columns: auto auto auto;
		}

		@include breakpoint($bp-xxl) {
			margin-top: 0;
		}

		&--left {
			> * {
				margin-bottom: 20px;

				@include breakpoint($bp-large) {
					margin-bottom: 0px;
				}
			}

			.footer__text {
				* {
					font-size: 0.75rem;
				}
				@include breakpoint($bp-large) {
					width: 120%;
				}

				@include breakpoint($bp-large) {
					display: flex;

					> * {
						margin-right: 35px;
					}
				}
			}
		}

		&--right {
			grid-column: 1;
			display: flex;
			flex-direction: row;
			padding: 50px 0 25px 0;
			justify-self: flex-start;
			flex-wrap: wrap;

			@include breakpoint($bp-small) {
				flex-wrap: nowrap;
			}

			@include breakpoint($bp-large) {
				grid-column: 3;
				justify-self: flex-end;
			}

			.image {
				height: 30px;
				position: relative;
				width: auto;
				margin-left: 0px;
				margin-right: 10px;

				@include breakpoint($bp-large) {
					margin-left: 10px;
				}

				@include breakpoint($bp-xxl) {
					height: 45px;
					margin-left: 30px;
				}
			}
		}
	}

	&__bottom {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		border-top: 1px solid $c-white;
		padding: 25px 0 40px 0;

		@include breakpoint($bp-xl) {
			flex-direction: row;
			display: flex;
		}

		&--left {
			display: flex;
			flex-direction: column;

			@include breakpoint($bp-medium) {
				flex-direction: row;
				display: flex;
			}
		}

		&--right {
			margin-top: 0;

			@include breakpoint($bp-medium) {
				margin-top: 20px;
			}

			@include breakpoint($bp-large) {
				margin-top: 0;
			}
		}

		#legal-menu {
			flex-direction: row;
			justify-content: flex-start;
			align-items: flex-end;
			margin: 10px 0;

			@include breakpoint($bp-medium) {
				margin: 0 0 0 20px;
			}

			.menu-item {
				margin-left: 0;
				margin-right: 20px;
			}
		}
	}
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fadeIn {
    animation-name: fadeIn;
    animation-duration: 2s;
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(-3px);
    }

    to {
        opacity: 1;
        transform: translateY(0px);
    }
}

.fadeInUp {
    animation-name: fadeInUp;
    opacity: 0;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}

@keyframes moveUp {
    from {
        transform: translateY(110%);
    }

    to {
        transform: translateY(0px);
    }
}

.moveUp {
    animation-name: moveUp;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    transform: translateY(110%);
}

@keyframes growY {
    from {
        transform: scaleY(0);
    }

    to {
        transform: scaleY(1);
    }
}

.growDown {
    transform: scaleY(0);
    transform-origin: top;
    animation-fill-mode: forwards;
    animation-name: growY;
    animation-duration: 1s;
}

@keyframes growX {
    from {
        transform: scaleX(0);
    }

    to {
        transform: scaleX(1);
    }
}

.growRight {
    transform-origin: left;
    animation-name: growX;
    animation-duration: 1s;
}

.growLeft {
    transform-origin: right;
    animation-name: growX;
    animation-duration: 1s;
}

.growCentre {
    transform-origin: center;
    animation-name: growX;
    animation-duration: 1s;
    transform: scaleX(0);
    animation-fill-mode: forwards;
}

@keyframes fadeInLeft {
    from {
        opacity: 0;
        transform: translate3d(-10%, 0, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

.fadeInLeft {
    animation-name: fadeInLeft;
    animation-duration: 1s;
}

@keyframes fadeInRight {
    from {
        opacity: 0;
        transform: translate3d(10%, 0, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

.fadeInRight {
    animation-name: fadeInRight;
    animation-duration: 1s;
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translate3d(0, 10%, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

.fadeInBottom {
    animation-name: fadeInUp;
    animation-duration: 0.4s;
}

//Typography (h1-6, p, a, blockquote, etc.) should be included

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $f-primary;
    font-weight: 400;
    text-transform: uppercase;

    .section--light & {
        color: $c-brand-blue--light;
    }
}

h1 {
    @include clamp("font-size", 1.875rem, calc(1.625rem + 1.25vw), 3.125rem);
    line-height: 1em;

    @include breakpoint($bp-medium) {
        line-height: 1.45em;
    }
}

h2 {
    @include clamp("font-size", 1.25em, calc(1.125rem + 0.625vw), 1.875em);
    line-height: 1.45em;
}

h3 {
    @include clamp("font-size", 1.25em, calc(1.225rem + 0.125vw), 1.375em);
    line-height: 1.45em;
}

.timeline-date {
    @include clamp("font-size", 1.875rem, calc(1.625rem + 1.25vw), 3.125rem);
}

// max 30 min 20

* {
    font-family: $f-secondary;
    font-weight: 400;
    font-style: normal;
}

p {
    font-family: $f-secondary;
    @include clamp("font-size", 0.937em, calc(0.8994rem + 0.188vw), 1.125em);
    line-height: 1.6em;
}

a {
    letter-spacing: 2px;
    text-decoration: none;
    font-family: $f-secondary;
    font-weight: 400;
    font-style: normal;
    line-height: 1.6em;
    transition: 0.3s;

    .section--dark & {
        &:hover {
            color: $c-brand-green;
        }
    }
}

.landscape-image-with-text .section__container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  align-items: center;

  .ie & {
    display: flex;
    justify-content: space-between;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    margin-top: 25px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 50px;

    .ie & {
      width: 350px;
    }

    > * {
      margin: 10px 0;

      .ie & {
        width: 100%;
      }

      @include breakpoint($bp-xl) {
        margin: 22px 0;
      }
    }

    p {
      @include breakpoint($bp-xl) {
        width: 90%;
      }
    }
  }

  .image__wrapper {
    .decorative-block--green {
      left: auto;
      right: 0;
    }
  }

  .decorative-block--blue {
    display: none;
    @include breakpoint($bp-xl) {
      display: block;
    }
  }
}

.landscape-image-with-text .section__container {
  &.standard-layout {
    @include breakpoint($bp-xl) {
      grid-template-columns: 65% 35%;
      grid-template-rows: 1fr;
    }

    .image__wrapper {
      padding-bottom: 100%;
      max-height: 300px;

      .ie & {
        padding-bottom: 0;
        max-height: 550px;
        width: 850px;
        height: 550px;
      }

      @include breakpoint($bp-small) {
        padding-bottom: 50%;
        height: 100%;
        max-height: 1000px;
      }

      @include breakpoint($bp-xl) {
        padding-bottom: 63.7%;
        height: 100%;
      }
    }

    .content {
      margin-bottom: 0;

      .ie & {
        p {
          width: 100%;
          margin-right: 0;
        }
      }

      @include breakpoint($bp-xl) {
        margin-top: 0;
        grid-column: 2;
        grid-row: 1;
        padding-right: 0;
        padding-left: calc(min(7vw, 135px));
      }
    }
  }

  &.flip-layout {
    @include breakpoint($bp-xl) {
      grid-template-columns: 35% 65%;
    }

    .image__wrapper {
      padding-bottom: 100%;
      max-height: 300px;
      grid-column: 1;

      @include breakpoint($bp-xl) {
        grid-column: 2;
        grid-template-columns: 35% 65%;
      }

      .ie & {
        padding-bottom: 0;
        max-height: 550px;
        width: 850px;
        height: 550px;
        order: 2;
      }

      @include breakpoint($bp-small) {
        padding-bottom: 50%;
        height: 100%;
        max-height: 1000px;
      }

      @include breakpoint($bp-xl) {
        padding-bottom: 63.7%;
      }
    }

    .content {
      grid-column: 1;
      @include breakpoint($bp-xl) {
        grid-row: 1;
      }
      .ie & {
        order: 1;
        padding: 0;
      }

      @include breakpoint($bp-xl) {
        padding-right: 100px;
      }

      @include breakpoint($bp-xxl) {
        padding-right: 200px;
      }
    }
  }
}

.form-template {
  padding-top: none;
  padding-bottom: calc(min(10.4vw, 200px));

  .flexible-section {
    padding-top: calc(min(5.2vw, 100px));
  }

  .section__container {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 100px;

    .ie & {
      display: flex;
      justify-content: space-between;
    }

    @include breakpoint($bp-medium) {
      grid-template-columns: 1fr 1fr;
    }
  }
}

.form {
  .ie & {
    width: 47.5%;
  }

  &__info {
    position: relative;
    align-self: flex-start;
    margin-bottom: 50px;

    .ie & {
      width: 47.5%;
      top: 0;
    }

    @include breakpoint($bp-medium) {
      margin-bottom: 0;
      position: sticky;
      top: 150px;
    }

    h1 {
      margin-bottom: 25px;
    }

    .image__wrapper {
      margin-top: 50px;
      width: 100%;
      padding-bottom: 127.185%;
    }
  }

  &__wrapper {
    .ie & {
      width: 50%;
      top: 0;
    }
  }
}

.wpforms-field-container {
  input[type='text'],
  input[type='tel'],
  input[type='date'],
  input[type='email'],
  input[type='number'],
  input[type='range'],
  input[type='url'],
  input[type='password'],
  textarea,
  select {
    width: 100%;
    padding: 20px;
    font-size: 1rem;
    border-style: none;
    border: 1px solid $c-brand-blue--light;
    color: $c-text;
    margin-bottom: 10px;

    &::placeholder {
      color: $c-brand-blue--light;
    }

    &.wpforms-error {
      color: $c-error;
      border-color: $c-error;
    }

    &.wpforms-valid {
      color: $c-brand-green;
      border-color: $c-brand-green;
    }
  }

  select {
    color: $c-brand-blue--light;
    height: 60px;
    padding: 0 20px;

    > option {
      color: $c-brand-blue--light;
    }
  }

  .wpforms-has-error {
    .wpforms-field-label {
      color: $c-error;
    }
  }

  .wpforms-field-row {
    width: 100%;
    display: flex;
    flex-direction: column;

    @include breakpoint($bp-large) {
      flex-direction: row;
    }

    > :not(.wpforms-one-half) {
      width: 100%;
    }
  }

  .wpforms-one-half {
    width: 100%;
    align-self: stretch;

    &:last-child {
      @include breakpoint($bp-large) {
        margin-left: 20px;
      }
    }
  }

  textarea {
    min-height: 200px;
  }

  .iti {
    margin-bottom: 10px;
  }

  h2 {
    margin-top: 20px;
  }

  h3 {
    margin-top: 20px;
  }

  p {
    margin-top: 10px;
  }

  .wpforms-field-label {
    position: relative;
    color: $c-brand-blue--light;
    transition: 0.3s;
    top: 0;
    opacity: 1;
    margin-bottom: 10px;
    display: block;
  }

  .wpforms-field-sublabel {
    display: none;
  }

  .wpforms-field-label-inline {
    margin-left: 10px;
    color: $c-brand-blue--light;
  }

  .wpforms-field-rating-items {
    > * {
      margin-right: 5px;
    }
  }

  br {
    height: 100px;
  }

  .wpforms-error {
    color: $c-error;
    border-color: $c-error;
  }

  .disclaimer {
    font-size: 0.75rem;
  }

  .wpforms-field {
    padding: 10px 0;

    &-checkbox,
    &-radio,
    &-address,
    &-date-time,
    &-file-upload,
    &-password,
    &-rating,
    &-name {
      label {
        top: 0;
        opacity: 1;
      }
    }

    &-radio,
    &-checkbox {
      ul {
        display: flex;
        flex-direction: column;

        @include breakpoint($bp-large) {
          flex-direction: row;
        }
      }

      li {
        margin: 10px 0;

        &:not(:first-child) {
          @include breakpoint($bp-large) {
            margin-left: 10px;
          }
        }
      }
    }
  }

  .wpforms-field-address {
    &-address1 {
      width: 100%;
    }
  }

  input[type='range'] {
    -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
    width: 100%; /* Specific width is required for Firefox. */
    background: transparent; /* Otherwise white in Chrome */
    padding: 0px;
    border-radius: 20px;
  }

  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
  }

  input[type='range']:focus {
    outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
  }

  input[type='range']::-ms-track {
    width: 100%;
    cursor: pointer;

    /* Hides the slider so custom styles can be added */
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  /* Special styling for WebKit/Blink */
  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: 1px solid #000000;
    height: 16px;
    width: 16px;
    border-radius: 10000px;
    background: $c-brand-blue--light;
    cursor: pointer;
  }

  /* All the same stuff for Firefox */
  input[type='range']::-moz-range-thumb {
    border: 1px solid #000000;
    height: 36px;
    width: 16px;
    border-radius: 3px;
    background: $c-brand-blue--light;
    cursor: pointer;
  }

  /* All the same stuff for IE */
  input[type='range']::-ms-thumb {
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    border: 1px solid #000000;
    height: 36px;
    width: 16px;
    border-radius: 3px;
    background: $c-brand-blue--light;
    cursor: pointer;
  }
}

.wpforms-page-indicator {
  * {
    color: $c-brand-blue--light;
    text-transform: uppercase;
  }
}

.wpforms-submit,
.wpforms-page-next {
  width: 100%;
  background-color: $c-brand-blue--light;
  color: $c-white;
  border: none;
  padding: 20px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 0.75rem;
  margin-top: 15px;
}

div.wpforms-container .wpforms-field-rating svg {
  fill: transparent;
  stroke: $c-brand-blue--light;
  stroke-width: 20px;
  opacity: 1;
  transition: 0.2s;
  cursor: pointer;
}
div.wpforms-container .wpforms-field-rating-item svg:hover {
  fill: $c-brand-blue--light;
}
div.wpforms-container .wpforms-field-rating-item.selected svg {
  fill: $c-brand-blue--light;
  transform: none;
}

div.wpforms-container .wpforms-field-rating-item.hover svg {
  transform: none;
  fill: $c-brand-blue--light;
}

.wpforms-field-rating-item {
  span,
  input[type='radio'] {
    display: none;
  }
}

.wpforms-entry-preview {
  &-label {
    margin-top: 15px;
    margin-bottom: 5px;
    color: $c-brand-blue--light;
    text-transform: uppercase;
  }
}

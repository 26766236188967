.four-columns-text {
  padding-top: calc(min(15.4vw, 80px));

  .section__container {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: calc(min(4.79vw, 100px));

    .ie & {
      display: flex;
      justify-content: space-between;
    }

    @include breakpoint($bp-medium) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  .col {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 50px;

    .ie & {
      width: 23%;
      > * {
        width: 100%;
      }
    }

    @include breakpoint($bp-medium) {
      margin-top: 0;
    }
  }

  .content {
    > * {
      margin-bottom: 10px;
    }

    p {
      font-size: 0.938rem;
    }

    h2 {
      @include clamp('font-size', 1.125rem, calc(0.8125rem + 1vw), 1.375rem);
    }

    h3 {
      @include clamp('font-size', 1.125rem, calc(1.2813rem + -0.5vw), 1rem);
    }
  }
}

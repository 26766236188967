.gallery-link {
    .section__container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .gallery__wrapper {
        width: 100%;
        margin: 35px;
    }
}

.portrait-image-with-text {
  .section__container {
    @supports (display: grid) {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto auto;
    }

    align-items: center;

    .ie & {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-evenly;
    }

    @include breakpoint($bp-medium) {
      @supports (display: grid) {
        grid-template-columns: 1.05fr 1fr;
        grid-template-rows: 1fr;
      }

      &.flip-layout {
        @supports (display: grid) {
          grid-template-columns: 1fr 1.05fr;
        }
      }
    }

    &.flip-layout {
      .image__wrapper {
        @supports (display: grid) {
          grid-column: 1;
        }

        .ie & {
          width: 50%;
          height: 750px;
          padding-bottom: 0;
          order: 2;
        }

        @include breakpoint($bp-medium) {
          @supports (display: grid) {
            grid-column: 2;
          }
        }
      }

      .content {
        @supports (display: grid) {
          grid-column: 1;
        }

        .ie & {
          width: 50%;
          order: 1;
        }

        > * {
          .ie & {
            width: 100%;
          }
        }

        @include breakpoint($bp-xl) {
          padding-left: 45px;
          padding-right: 170px;
        }

        @include breakpoint($bp-large) {
          padding-left: 45px;
        }
      }
    }
  }

  .image__wrapper {
    padding-bottom: 113.9%;

    @supports (display: grid) {
      grid-row: 1;
    }

    .ie & {
      width: 50%;
      height: 750px;
      padding-bottom: 0;
    }

    @include breakpoint($bp-medium) {
      padding-bottom: 115.5%;
      padding-right: 0;

      @supports (display: grid) {
        grid-column: 1;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 50px;

    > * {
      .ie & {
        width: 100%;
      }
    }

    .ie & {
      width: 50%;
    }

    @include breakpoint($bp-medium) {
      margin-top: 0;

      @supports (display: grid) {
        grid-column: 2;
        grid-row: 1;
      }
    }

    @include breakpoint($bp-large) {
      padding-left: calc(min(10.9vw, 210px));
    }

    > * {
      margin: 10px 0;

      @include breakpoint($bp-medium) {
        margin: 22px 0;
      }
    }

    p {
      @include breakpoint($bp-medium) {
        width: 90%;
      }
    }
  }
}

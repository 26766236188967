.blue-block-text {
    .section__container {
        background-color: $c-brand-blue;
        padding: 40px 20px;

        @include breakpoint($bp-vsmall) {
            padding: 70px 40px;
        }

        @include breakpoint($bp-small) {
            padding: 70px 95px;
        }
    }

    .content > *:not(:last-child) {
        margin-bottom: 15px;

        @include breakpoint($bp-small) {
            margin-bottom: 35px;
        }
    }

    .decorative-block {
        width: 60%;
        border-right: 1px solid white;
        height: 30px;
        position: absolute;
        right: 20px;
        top: 0;

        @include breakpoint($bp-small) {
            height: 90px;
            right: 95px;
        }
    }
}

:root {
  --header-height: 106px;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

html {
  scroll-behavior: smooth;
}

body {
  -ms-overflow-style: none;
}

.table-wrapper {
  overflow-x: scroll;
  max-width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
}

.content--wysiwyg {
  overflow-x: hidden;
  overflow-y: hidden;
  // width: 92%;
  &::-webkit-scrollbar {
    display: none;
  }

  @include breakpoint($bp-medium) {
    width: 100%;
  }

  table {
    tbody {
      color: $c-white;
      background-color: $c-brand-blue;
      td {
        padding: 16px;

        color: $c-white;

        h2,
        h3,
        h4,
        h5,
        h6 {
          color: $c-white;
          span {
            color: $c-white;
          }
        }

        a,
        a:visited,
        a:active {
          color: white;
        }
      }
      tr:nth-of-type(2n-1) {
        background-color: rgba($c-white, 0.36);
      }
    }
  }

  ul {
    list-style: inside;
    list-style-position: inside;
  }

  li {
    color: $c-brand-blue--light;
    // text-transform: uppercase;
    margin-bottom: 25px;
    @include clamp('font-size', 0.937em, calc(0.8994rem + 0.188vw), 1.125em);
    line-height: 1.5em;

    text-indent: -1.4em;
    padding-left: 1.4em;

    ::marker {
      margin-right: 0;
    }
  }

  a,
  a:active,
  a:visited {
    color: $c-brand-blue--light;

    &:hover {
      color: $c-brand-green;
    }

    &:not(.button) {
      letter-spacing: 1px;
      text-decoration: underline;
    }
  }

  span.button > a {
    text-decoration: none;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    &:not(:first-of-type) {
      margin-top: 45px;
    }
  }

  strong {
    font-weight: 600;
  }

  p {
    margin-bottom: 20px;
  }
}

%button-reset {
  background: none;
  border: 0;
  border-radius: 0;
  color: inherit;
  font: inherit; // Can cause issues when extending other fonts
  line-height: normal;
  overflow: visible;
  padding: 0;
  position: relative;
  user-select: none;
  cursor: pointer;
}

button {
  @extend %button-reset;
  // other styles…
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

//accessibility link classes
.visually-hidden {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.visually-hidden:focus {
  clip: auto;
  height: auto;
  overflow: auto;
  position: absolute;
  width: auto;
}

/*
  If the user has expressed their preference for
  reduced motion, then don't use animations on buttons.
*/
@media (prefers-reduced-motion: reduce) {
  * {
    animation: none;
  }
}

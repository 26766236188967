.not-selectable {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.carousel {
  position: relative;
  box-sizing: border-box;
}
.carousel *,
.carousel *:before,
.carousel *:after {
  box-sizing: inherit;
}
.carousel.is-draggable {
  cursor: move;
  cursor: grab;
}
.carousel.is-dragging {
  cursor: move;
  cursor: grabbing;
}
.carousel__viewport {
  position: relative;
  overflow: hidden;
  max-width: 100%;
  max-height: 100%;
}
.carousel__track {
  display: flex;
}
.carousel__slide {
  flex: 0 0 auto;
  width: var(--carousel-slide-width, 60%);
  max-width: 100%;
  padding: 1rem;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;
  touch-action: pan-y;
}
.carousel.has-dots {
  margin-bottom: calc(0.5rem + 22px);
}
.carousel__dots {
  margin: 0 auto;
  padding: 0;
  position: absolute;
  top: calc(100% + 0.5rem);
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  list-style: none;
  user-select: none;
}
.carousel__dots .carousel__dot {
  margin: 0;
  padding: 0;
  display: block;
  position: relative;
  width: 22px;
  height: 22px;
  cursor: pointer;
}
.carousel__dots .carousel__dot:after {
  content: '';
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: currentColor;
  opacity: 0.25;
  transition: opacity 0.15s ease-in-out;
}
.carousel__dots .carousel__dot.is-selected:after {
  opacity: 1;
}
.carousel__button {
  width: var(--carousel-button-width, 48px);
  height: var(--carousel-button-height, 48px);
  padding: 0;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: all;
  cursor: pointer;
  color: var(--carousel-button-color, currentColor);
  background: var(--carousel-button-bg, transparent);
  border-radius: var(--carousel-button-border-radius, 50%);
  box-shadow: var(--carousel-button-shadow, none);
  transition: opacity 0.15s ease;
}
.carousel__button.is-prev,
.carousel__button.is-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.carousel__button.is-prev {
  left: 10px;
}
.carousel__button.is-next {
  right: 10px;
}
.carousel__button[disabled] {
  cursor: default;
  opacity: 0.3;
}
.carousel__button svg {
  width: var(--carousel-button-svg-width, 50%);
  height: var(--carousel-button-svg-height, 50%);
  fill: none;
  stroke: currentColor;
  stroke-width: var(--carousel-button-svg-stroke-width, 1.5);
  stroke-linejoin: bevel;
  stroke-linecap: round;
  filter: var(--carousel-button-svg-filter, none);
  pointer-events: none;
}
body.compensate-for-scrollbar {
  overflow: hidden !important;
  touch-action: none;
}
.fancybox__container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  direction: ltr;
  margin: 0;
  padding: env(safe-area-inset-top, 0px) env(safe-area-inset-right, 0px) env(safe-area-inset-bottom, 0px) env(safe-area-inset-left, 0px);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  color: var(--fancybox-color, #fff);
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  z-index: 10050;
  outline: none;
  transform-origin: top left;
  --carousel-button-width: 48px;
  --carousel-button-height: 48px;
  --carousel-button-svg-width: 24px;
  --carousel-button-svg-height: 24px;
  --carousel-button-svg-stroke-width: 2.5;
  --carousel-button-svg-filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.4));
}
.fancybox__container *,
.fancybox__container *::before,
.fancybox__container *::after {
  box-sizing: inherit;
}
.fancybox__container :focus {
  outline: thin dotted;
}
body.is-using-mouse .fancybox__container :focus {
  outline: none;
}
@media all and (min-width: 1024px) {
  .fancybox__container {
    --carousel-button-width: 48px;
    --carousel-button-height: 48px;
    --carousel-button-svg-width: 27px;
    --carousel-button-svg-height: 27px;
  }
}
.fancybox__backdrop {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background: var(--fancybox-bg, rgba(24, 24, 27, 0.92));
}
.fancybox__carousel {
  position: relative;
  flex: 1 1 auto;
  min-height: 0;
  height: 100%;
  z-index: 10;
}
.fancybox__carousel.has-dots {
  margin-bottom: calc(0.5rem + 22px);
}
.fancybox__viewport {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: visible;
  cursor: default;
}
.fancybox__track {
  display: flex;
  height: 100%;
}
.fancybox__slide {
  flex: 0 0 auto;
  width: 100%;
  max-width: 100%;
  margin: 0;
  padding: 48px 8px 8px 8px;
  position: relative;
  overscroll-behavior: contain;
  display: flex;
  flex-direction: column;
  outline: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  --carousel-button-width: 36px;
  --carousel-button-height: 36px;
  --carousel-button-svg-width: 22px;
  --carousel-button-svg-height: 22px;
}
.fancybox__slide::before,
.fancybox__slide::after {
  content: '';
  flex: 0 0 0;
  margin: auto;
}
@media all and (min-width: 1024px) {
  .fancybox__slide {
    padding: 64px 100px;
  }
}
.fancybox__content {
  margin: 0 env(safe-area-inset-right, 0px) 0 env(safe-area-inset-left, 0px);
  padding: 36px;
  color: var(--fancybox-content-color, #374151);
  background: var(--fancybox-content-bg, #fff);
  position: relative;
  align-self: center;
  display: flex;
  flex-direction: column;
  z-index: 20;
}
.fancybox__caption {
  align-self: center;
  max-width: 100%;
  margin: 0;
  padding: 1rem 0 0 0;
  line-height: 1.375;
  color: var(--fancybox-color, currentColor);
  visibility: visible;
  cursor: auto;
  flex-shrink: 0;
  overflow-wrap: anywhere;
}
.is-loading .fancybox__caption {
  visibility: hidden;
}
.fancybox__container > .carousel__dots {
  top: 100%;
  color: var(--fancybox-color, #fff);
}
.fancybox__nav .carousel__button {
  z-index: 40;
}
.fancybox__nav .carousel__button.is-next {
  right: 8px;
}
@media all and (min-width: 1024px) {
  .fancybox__nav .carousel__button.is-next {
    right: 40px;
  }
}
.fancybox__nav .carousel__button.is-prev {
  left: 8px;
}
@media all and (min-width: 1024px) {
  .fancybox__nav .carousel__button.is-prev {
    left: 40px;
  }
}
.carousel__button.is-close {
  position: absolute;
  top: 8px;
  right: 8px;
  top: calc(env(safe-area-inset-top, 0px) + 8px);
  right: calc(env(safe-area-inset-right, 0px) + 8px);
  z-index: 40;
}
@media all and (min-width: 1024px) {
  .carousel__button.is-close {
    right: 40px;
  }
}
.fancybox__content > .carousel__button.is-close {
  position: absolute;
  top: -36px;
  right: 0;
  color: var(--fancybox-color, #fff);
}
.fancybox__no-click,
.fancybox__no-click button {
  pointer-events: none;
}
.fancybox__spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  color: var(--fancybox-color, currentColor);
}
.fancybox__slide .fancybox__spinner {
  cursor: pointer;
  z-index: 1053;
}
.fancybox__spinner svg {
  animation: fancybox-rotate 2s linear infinite;
  transform-origin: center center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 100%;
  height: 100%;
}
.fancybox__spinner svg circle {
  fill: none;
  stroke-width: 2.75;
  stroke-miterlimit: 10;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: fancybox-dash 1.5s ease-in-out infinite;
  stroke-linecap: round;
  stroke: currentColor;
}
@keyframes fancybox-rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes fancybox-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
.fancybox__backdrop,
.fancybox__caption,
.fancybox__nav,
.carousel__dots,
.carousel__button.is-close {
  opacity: var(--fancybox-opacity, 1);
}
.fancybox__container.is-animated[aria-hidden='false'] .fancybox__backdrop,
.fancybox__container.is-animated[aria-hidden='false'] .fancybox__caption,
.fancybox__container.is-animated[aria-hidden='false'] .fancybox__nav,
.fancybox__container.is-animated[aria-hidden='false'] .carousel__dots,
.fancybox__container.is-animated[aria-hidden='false'] .carousel__button.is-close {
  animation: 0.15s ease backwards fancybox-fadeIn;
}
.fancybox__container.is-animated.is-closing .fancybox__backdrop,
.fancybox__container.is-animated.is-closing .fancybox__caption,
.fancybox__container.is-animated.is-closing .fancybox__nav,
.fancybox__container.is-animated.is-closing .carousel__dots,
.fancybox__container.is-animated.is-closing .carousel__button.is-close {
  animation: 0.15s ease both fancybox-fadeOut;
}
.fancybox-fadeIn {
  animation: 0.15s ease both fancybox-fadeIn;
}
.fancybox-fadeOut {
  animation: 0.1s ease both fancybox-fadeOut;
}
.fancybox-zoomInUp {
  animation: 0.2s ease both fancybox-zoomInUp;
}
.fancybox-zoomOutDown {
  animation: 0.15s ease both fancybox-zoomOutDown;
}
.fancybox-throwOutUp {
  animation: 0.15s ease both fancybox-throwOutUp;
}
.fancybox-throwOutDown {
  animation: 0.15s ease both fancybox-throwOutDown;
}
@keyframes fancybox-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fancybox-fadeOut {
  to {
    opacity: 0;
  }
}
@keyframes fancybox-zoomInUp {
  from {
    transform: scale(0.97) translate3d(0, 16px, 0);
    opacity: 0;
  }
  to {
    transform: scale(1) translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes fancybox-zoomOutDown {
  to {
    transform: scale(0.97) translate3d(0, 16px, 0);
    opacity: 0;
  }
}
@keyframes fancybox-throwOutUp {
  to {
    transform: translate3d(0, -30%, 0);
    opacity: 0;
  }
}
@keyframes fancybox-throwOutDown {
  to {
    transform: translate3d(0, 30%, 0);
    opacity: 0;
  }
}
.fancybox__carousel .carousel__slide {
  scrollbar-width: thin;
  scrollbar-color: #ccc rgba(255, 255, 255, 0.1);
}
.fancybox__carousel .carousel__slide::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.fancybox__carousel .carousel__slide::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.1);
}
.fancybox__carousel .carousel__slide::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 2px;
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.2);
}
.fancybox__carousel.is-draggable .fancybox__slide,
.fancybox__carousel.is-draggable .fancybox__slide .fancybox__content {
  cursor: move;
  cursor: grab;
}
.fancybox__carousel.is-dragging .fancybox__slide,
.fancybox__carousel.is-dragging .fancybox__slide .fancybox__content {
  cursor: move;
  cursor: grabbing;
}
.fancybox__carousel .fancybox__slide .fancybox__content {
  cursor: auto;
}
.fancybox__carousel .fancybox__slide.can-zoom_in .fancybox__content {
  cursor: zoom-in;
}
.fancybox__carousel .fancybox__slide.can-zoom_out .fancybox__content {
  cursor: zoom-out;
}
.fancybox__carousel .fancybox__slide.is-draggable .fancybox__content {
  cursor: move;
  cursor: grab;
}
.fancybox__carousel .fancybox__slide.is-dragging .fancybox__content {
  cursor: move;
  cursor: grabbing;
}
.fancybox__image {
  transform-origin: 0 0;
  touch-action: none;
  user-select: none;
  transition: none;
}
.has-image .fancybox__content {
  padding: 0;
  background: transparent;
  min-height: 1px;
}
.is-closing .has-image .fancybox__content {
  overflow: visible;
}
.has-image[data-image-fit='contain'] {
  overflow: visible;
  touch-action: none;
}
.has-image[data-image-fit='contain'] .fancybox__image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
.has-image[data-image-fit='contain-w'] {
  overflow-x: hidden;
  overflow-y: auto;
}
.has-image[data-image-fit='contain-w'] .fancybox__content {
  min-height: auto;
}
.has-image[data-image-fit='contain-w'] .fancybox__image {
  max-width: 100%;
  height: auto;
}
.has-image[data-image-fit='cover'] {
  overflow: visible;
  touch-action: none;
}
.has-image[data-image-fit='cover'] .fancybox__content {
  width: 100%;
  height: 100%;
}
.has-image[data-image-fit='cover'] .fancybox__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.fancybox__carousel .fancybox__slide.has-iframe .fancybox__content,
.fancybox__carousel .fancybox__slide.has-map .fancybox__content,
.fancybox__carousel .fancybox__slide.has-pdf .fancybox__content,
.fancybox__carousel .fancybox__slide.has-video .fancybox__content,
.fancybox__carousel .fancybox__slide.has-html5video .fancybox__content {
  flex-shrink: 1;
  min-height: 1px;
  overflow: visible;
}
.fancybox__carousel .fancybox__slide.has-iframe .fancybox__content,
.fancybox__carousel .fancybox__slide.has-map .fancybox__content,
.fancybox__carousel .fancybox__slide.has-pdf .fancybox__content {
  width: 100%;
  height: 80%;
}
.fancybox__carousel .fancybox__slide.has-video .fancybox__content,
.fancybox__carousel .fancybox__slide.has-html5video .fancybox__content {
  width: 960px;
  height: 540px;
  max-width: 100%;
  max-height: 100%;
}
.fancybox__carousel .fancybox__slide.has-map .fancybox__content,
.fancybox__carousel .fancybox__slide.has-pdf .fancybox__content,
.fancybox__carousel .fancybox__slide.has-video .fancybox__content,
.fancybox__carousel .fancybox__slide.has-html5video .fancybox__content {
  padding: 0;
  background: rgba(24, 24, 27, 0.9);
  color: #fff;
}
.fancybox__carousel .fancybox__slide.has-map .fancybox__content {
  background: #e5e3df;
}
.fancybox__html5video,
.fancybox__iframe {
  border: 0;
  display: block;
  height: 100%;
  width: 100%;
  background: transparent;
}
.fancybox-placeholder {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
.fancybox__thumbs {
  flex: 0 0 auto;
  position: relative;
  padding: 0px 3px;
  opacity: var(--fancybox-opacity, 1);
}
.fancybox__container.is-animated[aria-hidden='false'] .fancybox__thumbs {
  animation: 0.15s ease-in backwards fancybox-fadeIn;
}
.fancybox__container.is-animated.is-closing .fancybox__thumbs {
  opacity: 0;
}
.fancybox__thumbs .carousel__slide {
  flex: 0 0 auto;
  width: var(--fancybox-thumbs-width, 96px);
  margin: 0;
  padding: 8px 3px;
  box-sizing: content-box;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: visible;
  cursor: pointer;
}
.fancybox__thumbs .carousel__slide .fancybox__thumb::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 5px solid var(--fancybox-accent-color, rgba(1, 210, 232, 0.94));
  opacity: 0;
  transition: opacity 0.15s ease;
  border-radius: 4px;
}
.fancybox__thumbs .carousel__slide.is-nav-selected .fancybox__thumb::after {
  opacity: 0.92;
}
.fancybox__thumbs .carousel__slide > * {
  pointer-events: none;
  user-select: none;
}
.fancybox__thumb {
  position: relative;
  width: 100%;
  padding-top: calc(100% / (var(--fancybox-thumbs-ratio, 1.5)));
  background-size: cover;
  background-position: center center;
  background-color: rgba(255, 255, 255, 0.1);
  background-repeat: no-repeat;
  border-radius: 4px;
}
.fancybox__toolbar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 20;
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.006) 8.1%,
    rgba(0, 0, 0, 0.021) 15.5%,
    rgba(0, 0, 0, 0.046) 22.5%,
    rgba(0, 0, 0, 0.077) 29%,
    rgba(0, 0, 0, 0.114) 35.3%,
    rgba(0, 0, 0, 0.155) 41.2%,
    rgba(0, 0, 0, 0.198) 47.1%,
    rgba(0, 0, 0, 0.242) 52.9%,
    rgba(0, 0, 0, 0.285) 58.8%,
    rgba(0, 0, 0, 0.326) 64.7%,
    rgba(0, 0, 0, 0.363) 71%,
    rgba(0, 0, 0, 0.394) 77.5%,
    rgba(0, 0, 0, 0.419) 84.5%,
    rgba(0, 0, 0, 0.434) 91.9%,
    rgba(0, 0, 0, 0.44) 100%
  );
  padding: 0;
  touch-action: none;
  display: flex;
  justify-content: space-between;
  --carousel-button-svg-width: 20px;
  --carousel-button-svg-height: 20px;
  opacity: var(--fancybox-opacity, 1);
  text-shadow: var(--fancybox-toolbar-text-shadow, 1px 1px 1px rgba(0, 0, 0, 0.4));
}
@media all and (min-width: 1024px) {
  .fancybox__toolbar {
    padding: 8px;
  }
}
.fancybox__container.is-animated[aria-hidden='false'] .fancybox__toolbar {
  animation: 0.15s ease-in backwards fancybox-fadeIn;
}
.fancybox__container.is-animated.is-closing .fancybox__toolbar {
  opacity: 0;
}
.fancybox__toolbar__items {
  display: flex;
}
.fancybox__toolbar__items--left {
  margin-right: auto;
}
.fancybox__toolbar__items--center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.fancybox__toolbar__items--right {
  margin-left: auto;
}
@media (max-width: 640px) {
  .fancybox__toolbar__items--center:not(:last-child) {
    display: none;
  }
}
.fancybox__counter {
  min-width: 72px;
  padding: 0 10px;
  line-height: var(--carousel-button-height, 48px);
  text-align: center;
  font-size: 17px;
  font-variant-numeric: tabular-nums;
  -webkit-font-smoothing: subpixel-antialiased;
}
.fancybox__progress {
  background: var(--fancybox-accent-color, rgba(1, 210, 232, 0.94));
  height: 3px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: scaleX(0);
  transform-origin: 0;
  transition-property: transform;
  transition-timing-function: linear;
  z-index: 30;
  user-select: none;
}
.fancybox__container:fullscreen::backdrop {
  opacity: 0;
}
.fancybox__button--fullscreen g:nth-child(2) {
  display: none;
}
.fancybox__container:fullscreen .fancybox__button--fullscreen g:nth-child(1) {
  display: none;
}
.fancybox__container:fullscreen .fancybox__button--fullscreen g:nth-child(2) {
  display: block;
}
.fancybox__button--slideshow g:nth-child(2) {
  display: none;
}
.fancybox__container.has-slideshow .fancybox__button--slideshow g:nth-child(1) {
  display: none;
}
.fancybox__container.has-slideshow .fancybox__button--slideshow g:nth-child(2) {
  display: block;
}

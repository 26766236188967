// sass-lint:disable no-important

// Block: skip-link
// TODO style this per site
.skip-link {
    position: relative;

    a {
        @include hide;

        &:focus {
            @include unhide;
            color: $c-white;
            position: absolute !important; // Sigh, but the important is needed for things that've been unhidden
            z-index: $z-over-all;
            top: 1px;
            left: 1px;
            padding: 0.5ex 1ex;
            outline: none;
            background: $c-brand-blue;
            border: 1px solid $c-brand-blue;
            white-space: nowrap;
        }
    }
}

// Skip targets are js generated <a> for skip links to go to
.skip-target {
    @include hide;
}

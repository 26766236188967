.subject-page {
    &.flexible-section {
        padding-bottom: 0;
    }

    .section__container {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto auto;
    }

    .campaign-image-with-text {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        align-items: center;
        margin-top: 100px;

        .ie & {
            > * {
                width: 47.5%;
            }
            display: flex;
            justify-content: space-between;
        }

        @include breakpoint($bp-medium) {
            grid-template-columns: 0.6fr 1fr;
            column-gap: 100px;
        }

        @include breakpoint($bp-xl) {
            column-gap: 230px;
            margin-top: 0px;
        }

        .right {
            position: relative;

            .image__wrapper {
                padding-bottom: 100%;

                @include breakpoint($bp-medium) {
                    padding-bottom: 150%;
                }

                @include breakpoint($bp-large) {
                    padding-bottom: 115.2%;
                }

                .image {
                    .ie & {
                        padding-bottom: 0;
                    }
                }
            }

            .content {
                padding-right: 100px;
            }

            .big-text {
                color: $c-white;
            }

            .overlay {
                background: linear-gradient(
                    to top,
                    rgba(#000, 1),
                    rgba(#000, 0) 90%
                );
                mix-blend-mode: multiply;
                opacity: 0.69;
            }
        }
    }
}

.subject-intro {
    &__content {
        margin-bottom: 50px;

        @include breakpoint($bp-medium) {
            margin-bottom: 0;
        }
        > * {
            margin-bottom: 15px;
        }
    }
}

.teacher-info {
    &__wrapper {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        align-items: center;

        .ie & {
            display: flex;
            > * {
                width: 30%;
            }
        }

        @include breakpoint($bp-medium) {
            grid-template-columns: auto 1fr;
        }

        .image__wrapper {
            width: 100%;
            height: auto;
            padding-bottom: 100%;

            .ie & {
                padding-bottom: 0;
                height: 300px;
            }

            @include breakpoint($bp-medium) {
                width: 316px;
                height: 365px;
            }
        }
    }

    &__content {
        color: $c-brand-blue--light;
        margin-top: 50px;

        @include breakpoint($bp-medium) {
            margin-top: 0px;
            margin-left: 80px;
        }

        ul {
            li {
                font-size: 1em;
                margin: 10px 0;
            }
        }
    }
}

.section {
  padding-left: 25px;
  padding-right: 25px;

  @supports (display: grid) {
    display: grid;
    > * {
      grid-column: 2/3;
      max-width: 100vw;
    }
  }

  @include breakpoint($bp-xl) {
    padding-left: 2.6vw;
    padding-right: 2.6vw;
  }

  // @include breakpoint($bp-hd) {
  //     padding-left: 0px;
  //     padding-right: 0px;
  // }

  &--dark * {
    color: $c-white;
  }

  &--xxl {
    @supports (display: grid) {
      @include section-width(117.25em);
    }

    // @include breakpoint($bp-medium) {
    //     padding-left: 0.575vw;
    //     padding-right: 0.575vw;
    // }

    // @include breakpoint($bp-xl) {
    //     padding-left: 1.15vw;
    //     padding-right: 1.15vw;
    // }
  }

  &--xl {
    @supports (display: grid) {
      @include section-width(112.5em);
    }

    // @include breakpoint($bp-medium) {
    //     padding-left: 1.3vw;
    //     padding-right: 1.3vw;
    // }

    // @include breakpoint($bp-xl) {
    //     padding-left: 2.6vw;
    //     padding-right: 2.6vw;
    // }
  }

  &--l {
    @supports (display: grid) {
      @include section-width(107.125em);
    }

    // @include breakpoint($bp-medium) {
    //     padding-left: 2.6vw;
    //     padding-right: 2.6vw;
    // }

    // @include breakpoint($bp-xl) {
    //     padding-left: 5.2vw;
    //     padding-right: 5.2vw;
    // }
  }

  &--m {
    @supports (display: grid) {
      @include section-width(102em);
    }

    // @include breakpoint($bp-medium) {
    //     padding-left: 3.75vw;
    //     padding-right: 3.75vw;
    // }

    // @include breakpoint($bp-xl) {
    //     padding-left: 7.5vw;
    //     padding-right: 7.5vw;
    // }
  }

  &--s {
    @supports (display: grid) {
      @include section-width(90.125em);
    }

    // @include breakpoint($bp-medium) {
    //     padding-left: 6.2vw;
    //     padding-right: 6.2vw;
    // }

    // @include breakpoint($bp-xl) {
    //     padding-left: 12.4vw;
    //     padding-right: 12.4vw;
    // }
  }

  &--xs {
    @supports (display: grid) {
      @include section-width(73.5em);
    }

    // @include breakpoint($bp-medium) {
    //     padding-left: 9.6875vw;
    //     padding-right: 9.6875vw;
    // }

    // @include breakpoint($bp-xl) {
    //     padding-left: 19.375vw;
    //     padding-right: 19.375vw;
    // }
  }

  &--xxs {
    @supports (display: grid) {
      @include section-width(47.375em);
    }

    // @include breakpoint($bp-medium) {
    //     padding-left: 15.185vw;
    //     padding-right: 15.185vw;
    // }

    // @include breakpoint($bp-xl) {
    //     padding-left: 30.37vw;
    //     padding-right: 30.37vw;
    // }
  }

  &__container {
    width: 100%;
    position: relative;
  }
}

.flexible-section {
  padding-top: calc(min(15.4vw, 120px));

  .ie & {
    padding-top: 200px;
  }

  &:last-of-type {
    padding-bottom: calc(min(20.2vw, 200px));

    .ie & {
      padding-bottom: 200px;
    }

    @include breakpoint($bp-small) {
      padding-bottom: calc(min(5.2vw, 100px));

      .ie & {
        padding-bottom: 100px;
      }
    }
  }

  .timeline-template & {
    padding-top: calc(min(5.2vw, 100px));

    &.timeline-heading {
      padding-bottom: 0;
    }

    .ie & {
      padding-top: 100px;
    }
  }
}

.call-to-action {
    .section__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        > * {
            margin-bottom: 40px;
        }
    }

    h2,
    h3 {
        line-height: 1.45em;
        text-align: center;
        width: 75%;
    }

    h2 {
        @include clamp(
            "font-size",
            1.875rem,
            calc(1.625rem + 1.25vw),
            3.125rem
        );
    }

    h3 {
        @include clamp("font-size", 1.25em, calc(1.125rem + 0.625vw), 1.875em);
    }

    .decorative-block {
        height: 15px;
        width: 100%;
        max-width: 341px;
        background-color: $c-brand-green;

        &--sub {
            width: 1px;
            max-height: 90px;
            height: 90px;
        }
    }
}

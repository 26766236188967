.campaign-image-with-text {
  .image-desktop {
    display: none;

    @include breakpoint($bp-small) {
      display: block;
    }
  }

  .image-mobile {
    display: block;

    @include breakpoint($bp-small) {
      display: none;
    }
  }

  .image {
    object-position: 50%;

    @include breakpoint($bp-small) {
      object-position: 70%;
    }

    &__wrapper {
      padding-bottom: 64.444%;
      min-height: 500px;
    }
  }

  .content {
    position: absolute;
    bottom: 6%;
    left: 5%;
    max-width: 750px;
  }

  .overlay {
    background: linear-gradient(to top, rgba(#000, 1), rgba(#000, 0) 90%);
    mix-blend-mode: multiply;
    opacity: 0.69;

    @include breakpoint($bp-medium) {
      background: linear-gradient(to right, rgba(#000, 0.69), rgba(#000, 0) 50%);
    }
  }

  .big-text {
    @include clamp('font-size', 1.25em, calc(0.875rem + 1.875vw), 3.125em);
    line-height: 1.45em;
  }

  .small-text {
    @include clamp('font-size', 0.75em, calc(0.675rem + 0.375vw), 1.125em);
    color: $c-brand-green;
    text-transform: uppercase;
  }

  .decorative-block {
    bottom: 0;
    left: 0;
    height: 0.7vw;
    min-height: 8px;
    width: 50%;
    max-width: 350px;
    background-color: $c-brand-green;
    position: absolute;
  }
}

.campaign-image-with-text--half-page {
  .section__container {
    @supports (display: grid) {
      display: grid;
      grid-template-columns: 1fr;
    }

    align-items: center;
    justify-content: center;

    .ie & {
      > * {
        width: 50%;
      }
      display: flex;
    }

    @include breakpoint($bp-medium) {
      grid-template-columns: 1fr 1fr;
      column-gap: 50px;
    }
  }

  .left {
    position: relative;
  }

  .image {
    object-position: 70%;

    @include breakpoint($bp-medium) {
      padding-bottom: 158.681%;
    }

    .ie & {
      padding-bottom: 0;
    }

    &__wrapper {
      min-height: 500px;

      @include breakpoint($bp-medium) {
        padding-bottom: 158.681%;
      }
    }
  }

  .overlay {
    background: linear-gradient(to top, rgba(#000, 1), rgba(#000, 0) 90%);
    mix-blend-mode: multiply;
    opacity: 0.69;
  }

  .right {
    display: flex;
    align-items: center;
    justify-content: center;

    .content {
      position: relative;
      bottom: auto;
      left: auto;
      max-width: 400px;
      margin-top: 50px;

      @include breakpoint($bp-medium) {
        margin-top: 0px;
      }

      > *:not(:last-child) {
        margin-bottom: 35px;
      }
    }
  }
}

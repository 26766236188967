.main-header {
  width: 100%;
  z-index: 2000;
  justify-content: center;
  top: 0;
  position: sticky;
  transition: 0.3s;
  height: 106px;
  height: var(--hero-height);

  .ie & {
    max-height: 106px;
    max-height: var(--hero-height);
    width: 100vw;
  }

  &__container {
    display: grid;
    grid-template-columns: 140px auto 140px;
    height: auto;
    position: relative;

    .ie & {
      display: flex;
      justify-content: space-between;
      max-height: 106px;
      max-height: var(--hero-height);
    }
  }

  &__logo {
    width: 150px;
    // height: 100%;
    transition: 0.3s;
    margin: 20px 0;
    position: relative;

    @include breakpoint($bp-large) {
      margin-top: 20px;
      width: 200px;
    }

    svg {
      max-width: 150px;
      height: auto;
    }

    a {
      width: 100%;
      height: auto;
      display: flex;
      align-items: center;
    }

    .hero-slideshow & {
      opacity: 1;
      // g * {
      //   fill: white;
      // }
      g {
        .green {
          fill: white;
        }
        .blue {
          fill: white;
        }
        .light-blue {
          fill: white;
        }
        .light-green {
          fill: white;
        }
      }

      // opacity: 0;
      // pointer-events: none;
    }

    .scrolled &,
    .spaced &,
    .no-hero & {
      opacity: 1;
      pointer-events: auto;
      g {
        .green {
          fill: #46b44e;
        }
        .blue {
          fill: #00407f;
        }
        .light-blue {
          fill: #2a628b;
        }
        .light-green {
          fill: #c6dad1;
        }
      }
    }
  }

  &.scrolled,
  &.spaced {
    background-color: $c-white;

    * {
      color: $c-text;
    }
  }

  &.hero-slideshow {
    position: fixed;

    &.spaced {
      position: sticky;
    }
  }

  &__right {
    color: $c-text;
    padding: 20px 0px;
    grid-column: 3;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;

    @include breakpoint($bp-medium) {
      padding: 35px 0px;
    }

    > * {
      margin-left: 10px;
      font-size: 1em;
      transition: 0.3s;

      @include breakpoint($bp-medium) {
        margin-left: 20px;
      }

      @include breakpoint($bp-large) {
        font-size: 1.125em;
      }
    }

    .hero-slideshow & {
      color: $c-white;
    }

    .spaced & {
      color: $c-text;
    }
  }
}

.burger-menu__toggle {
  width: 28px;
  display: inline-block;
  cursor: pointer;
  position: relative;
  transition: 0.2s;
  margin-top: 4px;

  @include breakpoint($bp-large) {
    width: 37px;
  }
  @media (min-width: 1440px) {
    display: none;
  }

  .touch-false & {
    @media (min-width: 1440px) {
      display: none;
    }
  }

  .touch-true & {
    display: inline-block;
  }

  hr {
    background-color: $c-white;
    border-style: none;
    height: 2px;
    margin: 0;
    transition: 0.2s;

    &:nth-of-type(1) {
      transform-origin: center;
    }
    &:nth-of-type(2) {
      margin: 5px 0;

      @include breakpoint($bp-large) {
        margin: 7px 0;
      }
    }
    &:nth-of-type(3) {
      transform-origin: center;
    }

    .section--light &,
    .scrolled & {
      background-color: $c-text;
    }
  }

  &.active {
    margin-top: 9px;
    transform: scale(0.8);
    height: 25px;

    hr {
      &:nth-of-type(1) {
        transform: rotate(45deg) translate(2px, 1px);
      }
      &:nth-of-type(2) {
        display: none;
      }
      &:nth-of-type(3) {
        transform: rotate(-45deg);
      }
    }
  }
}

.secondary-nav__wrapper {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
}

#gdpr-cookie-message {
  position: fixed;
  left: 2%;
  bottom: 2%;
  background-color: white;
  color: #414241;
  padding: 20px;
  border-radius: 0px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  z-index: 10000000;
  width: 96%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  @include breakpoint($bp-medium) {
    flex-direction: row;
  }

  p {
    margin: 0.6em 0;
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 1.6;
    text-align: center;
    @include breakpoint($bp-medium) {
      text-align: left;
    }
  }
  h4 {
    display: none;
  }

  p:last-child {
    margin: 0.6em 0;
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 1.6;
  }
  li {
    width: 49%;
    display: inline-block;

    > * {
      margin: 0.6em 0;
      font-size: 14px;
      letter-spacing: 1px;
      line-height: 1.6;
      appearance: checkbox;
    }
  }

  button,
  button#ihavecookiesBtn {
    block-size: fit-content;
    border: none;
    background: $c-brand-blue;
    color: white;
    font-size: 15px;
    padding: 10px;
    border-radius: 3px;
    margin-left: 0px;
    cursor: pointer;
    transition: all 0.3s ease-in;
    margin: 5px;
    border: 1px solid white;
  }
  button#gdpr-cookie-accept:hover {
    background: white;
    border: 1px solid $c-brand-blue;
    color: $c-brand-blue;
    transition: all 0.3s ease-in;
  }
  button#gdpr-cookie-advanced {
    background: white;
    color: black;
  }
  button:disabled {
    opacity: 0.3;
  }
  input[type='checkbox'] {
    float: none;
    margin-top: 0;
    margin-right: 5px;
  }
}

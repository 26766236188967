ul.menu {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: flex-start;
  justify-content: center;
  user-select: none;
  list-style: none;
  margin-bottom: 0;

  @include breakpoint($bp-large) {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  li {
    margin: 0 10px;
    text-transform: uppercase;
    border-bottom: none;
  }

  a {
    border-bottom: none;
    padding-bottom: 3px;
    transition: 0.2s;

    .section--light & {
      color: $c-text;
    }
  }
}

ul#primary {
  padding: 35px 0px;
  position: relative;
  display: none;

  .touch-false & {
    @media (min-width: 1440px) {
      display: flex;
    }
  }

  .touch-true & {
    .ie & {
      display: flex;
    }
  }

  .touch-true & {
    display: none;
  }

  > li {
    font-size: 12px;

    &::after {
      display: block;
      content: '';
      border-bottom: solid 1px $c-brand-green;
      transform: scaleX(0);
      transition: transform 250ms ease-in-out;
    }

    &:hover {
      &::after {
        transform: scaleX(1);
      }
    }
  }

  > li > a.maintainHover {
    color: $c-brand-green;
    // border-bottom: 1px solid $c-brand-green;
  }

  > .menu-item-has-children > .sub-menu {
    display: none;
    opacity: 0;
    transition: 0.3s;
    flex-direction: column;
    flex-wrap: wrap;
    width: 675px;
    height: 428px;
    padding: 25px 35px 29px;
    left: 50%;
    max-width: 100vw;
    max-height: 95vh;
    position: absolute;
    background-color: #fff;
    filter: drop-shadow(0 0 50px rgba(0, 0, 0, 0.3));
    top: 100%;
    transform: translateX(-75%);

    .ie & {
      z-index: 20000;
    }

    &::before {
      content: '';
      position: absolute;
      width: 150%;
      height: 100%;
      top: 0;
      left: 0;
      display: block;
      background-color: $c-white;
    }

    li {
      margin-left: 0;
      margin-right: 25px;
      z-index: 1;

      a {
        &:hover {
          color: $c-brand-green;
          text-decoration: none;
          border-bottom: none;
        }
      }

      a {
        letter-spacing: 1px;
        color: $c-text;
      }

      &:hover {
        .post-thumbnail {
          opacity: 1;
        }
      }
    }

    .post-thumbnail {
      width: calc(60% - 60px);
      padding-bottom: calc(63% - 55px);
      top: 27px;
      overflow: hidden;
      position: absolute;
      right: -49%;
      opacity: 0;
      transition: 0.3s;
      pointer-events: none;

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform-origin: center center;
      }
    }

    .level-2 {
      font-size: 14px;
      padding: 15px 0;
      border-bottom: 1px solid #151515;
      width: 265px;
    }

    .level-3 {
      font-size: 12px;
      margin-top: 10px;
      text-transform: none;

      a {
        letter-spacing: 1px;
      }
    }
  }
}

ul#footer-main {
  li {
    margin: 5px 0;
    text-transform: uppercase;

    @include breakpoint($bp-large) {
      margin: 0 20px;
    }

    &::after {
      display: block;
      content: '';
      border-bottom: solid 1px $c-brand-green;
      transform: scaleX(0);
      transition: transform 250ms ease-in-out;
    }

    &:hover {
      &::after {
        transform: scaleX(1);
      }
    }
  }
}

ul#secondary {
  display: none;
  flex-direction: column;
  align-items: stretch;
  background-color: $c-white;
  padding: 45px 50px 80px 50px;
  width: auto;
  height: auto;
  position: absolute;
  right: -50%;
  top: 100px;
  transition: 0.4s;
  z-index: 10000;
  pointer-events: auto;

  @include breakpoint($bp-xl) {
    display: flex;
  }

  &.active {
    right: 0;
    filter: drop-shadow(0 0 50px rgba($c-black, 0.3));
  }

  .sub-menu > li {
    padding-top: 10px;
    text-transform: none;

    a {
      letter-spacing: 1px;
    }
  }

  li {
    font-size: 12px;
    margin-left: 0;
  }

  a {
    &:hover {
      color: $c-brand-green;
      text-decoration: none;
      border-bottom: none;
    }
  }

  > li {
    font-size: 14px;
    padding: 15px 0;
    border-bottom: 1px solid $c-text;
  }
}

#mobile-navigation {
  position: fixed;
  top: 111.25px;
  left: -100vw;
  height: calc(100vh - var(--header-height));
  width: 100vw;
  background-color: $c-white;
  z-index: 1500;
  display: flex;
  justify-content: flex-start;
  opacity: 0;
  transition: left 0s, opacity 0.3s;

  .touch-false & {
    @media (min-width: 1440px) {
      display: none;
    }
  }

  .ie.touch-true & {
    @media (min-width: 1440px) {
      display: flex;
    }
  }

  .touch-true & {
    display: flex;
  }

  li,
  a {
    &:hover {
      border-bottom: none;
    }
  }

  &.active {
    left: 0;
    opacity: 1;
  }

  .menu-mobile-main-container {
    width: 100%;
    height: 100%;
    position: relative;
    margin-top: 10px;
    padding: 0 40px 20px 40px;
    overflow: scroll;
  }

  #mobile-main {
    align-items: stretch;
    justify-content: flex-start;
    top: 0;
    left: 0;
    transition: 0.3s;
    height: 100%;
    flex-direction: column;
    padding-bottom: 900px;

    > li {
      font-size: 20px;
      width: 100%;
      margin-bottom: 20px;
      margin-left: 0;
      margin-right: 0;
      display: flex;
      justify-content: center;

      > a {
        position: relative;
        width: 100%;
        display: block;
        max-width: 230px;
      }

      &.menu-item-has-children {
        .menu-expand {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 0;
          color: $c-brand-green;
        }
      }

      > .sub-menu {
        position: absolute;
        top: 0;
        left: 0;
        flex-direction: column;
        transition: 0.5s;
        transform: translateX(150%);
        display: none;
        width: 100%;

        .menu-close {
          color: $c-brand-green;
          margin-bottom: 20px;
          position: sticky;
          top: 0;
          left: 0;
          background-color: $c-white;
        }

        > .menu-item-has-children {
          margin-bottom: 15px;

          > .sub-menu .menu-item a {
            font-size: 14px;
            text-transform: none;
          }
        }

        > .menu-item {
          font-size: 15px;
          margin-bottom: 10px;

          a {
            margin-bottom: 5px;
            display: block;
          }

          &:last-child {
            padding-bottom: 100px;
          }
        }

        .menu-item {
          margin-left: 0;
        }
      }
    }
  }
}

.modal {
    height: auto;
    width: 85vw;
    max-width: 700px;
    background-color: $c-white;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    filter: drop-shadow(0 0 50px rgba($c-black, 0.3));
    position: relative;
    cursor: auto;

    > * {
        margin-bottom: 10px;
    }

    .logo {
        width: 40%;
        margin-bottom: 20px;

        @include breakpoint($bp-large) {
            width: 30%;
        }
    }

    @include breakpoint($bp-large) {
        padding: 30px 60px;
    }

    #modalClose {
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
        transition: 0.3s;

        &:hover {
            color: $c-brand-green;
        }

        @include breakpoint($bp-large) {
            top: 30px;
            right: 30px;
        }
    }

    &__wrapper {
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        z-index: 1400;
        height: 100vh;
        width: 100vw;
        background-color: rgba($c-black, 0.3);
        display: none;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &.active {
            display: flex;
        }
    }
}

.news-single {
    &__header {
        padding-top: calc(min(5.2vw, 100px));
        padding-bottom: calc(min(5.2vw, 100px));

        .post__intro {
            width: 50%;
        }

        .excerpt {
            margin-top: 15px;
        }

        .image__wrapper {
            padding-bottom: 59.3%;
            height: auto;
            margin-top: 25px;
        }
    }
}

.single-post {
    .flexible-wrap {
        section:first-of-type {
            padding-top: 0;
        }
    }
}

.post-meta {
    display: flex;
    margin-top: 25px;

    &__info {
        margin-left: 20px;
    }

    .avatar {
        border-radius: 9999px;
    }
}

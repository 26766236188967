.full-width-image-with-text {
    .image {
        &__wrapper {
            padding-bottom: 64.444%;
            min-height: 500px;
        }
    }

    .image-desktop {
        display: none;

        @include breakpoint($bp-small) {
            display: block;
        }
    }

    .image-mobile {
        display: block;
        object-position: 70%;

        @include breakpoint($bp-small) {
            display: none;
        }
    }

    h2 {
        @include clamp("font-size", 1.875em, calc(1.625rem + 1.25vw), 3.125em);
    }

    p {
        @include clamp("font-size", 1em, calc(0.95rem + 0.25vw), 1.25em);
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: absolute;
        bottom: 6%;
        left: 5%;
        max-width: 80%;

        .ie & {
            width: 600px;

            > * {
                width: 100%;
            }
        }

        @include breakpoint($bp-medium) {
            max-width: 700px;
        }

        @include breakpoint($bp-xl) {
            max-width: 425px;
        }

        > * {
            margin-bottom: 10px;

            @include breakpoint($bp-large) {
                margin-bottom: 35px;
            }
        }
    }

    .overlay {
        background: linear-gradient(
            to top,
            rgba(#000, 1),
            rgba(#000, 0.7) 50%,
            rgba(#000, 0) 100%
        );
        mix-blend-mode: multiply;
        opacity: 0.8;

        @include breakpoint($bp-medium) {
            background: linear-gradient(
                to top,
                rgba(#000, 1),
                rgba(#000, 0) 90%
            );
        }
    }

    .decorative-block {
        bottom: 0;
        left: 0;
        height: 0.7vw;
        min-height: 8px;
        width: 50%;
        max-width: 350px;
        background-color: $c-brand-green;
        position: absolute;
    }
}

.hero {
  width: 100%;
  background-color: $c-brand-blue;
  position: relative;

  @include breakpoint($bp-large) {
    min-height: 720px;
    height: 93.7vh;
  }

  .section {
    padding-left: 25px;
    padding-right: 25px;
    @include breakpoint($bp-xl) {
      padding-left: 2.6vw;
      padding-right: 2.6vw;
    }
  }

  &.hero--slideshow--padded {
    min-height: 600px;
    height: auto;

    @include breakpoint($bp-medium) {
      height: 86.75vh;
    }

    .section__container {
      padding-bottom: 60px;
    }
    .hero__image {
      .image {
        object-position: center;

        @include breakpoint($bp-small) {
          object-position: 70%;
        }
      }
    }
  }

  &--slideshow {
    height: 100vh;

    .hero__image {
      .image-desktop {
        display: block;

        @include breakpoint($bp-small) {
          display: block;
        }
      }

      .image-mobile {
        display: block;

        @include breakpoint($bp-small) {
          display: none;
        }
      }

      .image {
        object-position: 70%;

        @include breakpoint($bp-small) {
          object-position: 70%;
        }
      }
    }

    &--padded {
      background-color: $c-white;

      > .section > .section__container {
        padding-bottom: 20px;
      }
    }
  }

  .section {
    height: 100%;
  }

  .section__container {
    overflow: hidden;
    display: grid;
    height: 100%;
    position: relative;
    z-index: 1000;
    padding-bottom: 30px;

    .ie & {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    @include breakpoint($bp-medium) {
      padding-bottom: 0px;
      grid-template-rows: 10% 113px auto 116px;
    }
  }

  &__overlay {
    background-image: linear-gradient(rgba(#161615, 0.5) 0, rgba(#161615, 0) 20%, rgba(#161615, 0) 25%, rgba(#161615, 0.5) 40%, rgba(#161615, 1) 100%);
    position: relative;
    z-index: 500;

    @include breakpoint($bp-medium) {
      background-image: linear-gradient(rgba(#161615, 0.5) 0, rgba(#161615, 0) 30%, rgba(#161615, 0) 40%, rgba(#161615, 9) 100%);
    }

    .hero--slideshow--padded & {
      background-image: linear-gradient(rgba(#161615, 0) 0, rgba(#161615, 0.2) 30%, rgba(#161615, 0.5) 50%, rgba(#161615, 1) 100%);
    }
  }

  &__logo {
    width: 156px;
    position: relative;
    grid-row: 1;
    top: 20px;
    z-index: 10000;
    // max-width: 238px;

    @include breakpoint($bp-medium) {
      width: 30%;
      margin-top: 20px;
    }

    @include breakpoint($bp-xl) {
      width: 238px;
      margin-top: 0px;
      grid-row: 2;
    }

    img {
      width: 100%;
      height: auto;
      max-width: 238px;
    }
  }

  &__content {
    position: relative;
    width: 100%;
    grid-row: 3;
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 10;

    .ie & {
      align-self: flex-start;
      bottom: -200px;

      h1 {
        width: 100%;
      }

      .button {
        width: auto;
      }
    }

    @include breakpoint($bp-large) {
      width: 100%;
      max-width: 650px;
    }

    .decorative-block {
      height: 90px;
      border-right: 1px solid $c-brand-green;
      width: 10px;
      right: 0;
      left: auto;
      position: absolute;
      bottom: -30px;
      margin: 0;

      @include breakpoint($bp-medium) {
        bottom: -116px;
        border-left: 1px solid $c-brand-green;
        border-right: none;
        width: 100%;
        left: 0;
      }
    }

    > * {
      margin: 5px 0;

      @include breakpoint($bp-medium) {
        margin: 20px 0;
      }

      @include breakpoint((min-height 600px) (orientation landscape)) {
        margin: 10px 0;
      }
    }

    .hero--slideshow--padded & {
      position: relative;
      bottom: 0;
      margin-top: 200px;

      @include breakpoint($bp-medium) {
        position: absolute;
        bottom: 60px;
      }
    }
  }

  &__title {
  }

  &__secondary-text {
    @include clamp('font-size', 0.937rem, calc(0.8994rem + 0.188vw), 1.125rem);
    margin-bottom: 20px;
    width: 90%;

    @include breakpoint($bp-medium) {
      width: 90%;
    }
  }

  &__bottom {
    position: relative;
    grid-row: 4;
    margin-top: 30px;
    display: none;
    justify-content: center;
    align-items: center;

    .ie & {
      margin-bottom: 30px;
    }

    @include breakpoint($bp-large) {
      display: flex;
    }

    .hero--slideshow--padded & {
      border-left: 0px solid $c-brand-green;
    }
  }
}

.slides {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;

  .section__container {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding-bottom: 30px;
  }

  &__slide {
    opacity: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: opacity 0.2s, transform 1s;
    transition-delay: 2s;
    transform: scale(1.3);

    &.current-slide {
      opacity: 1;
      transform: scale(1);
      transition: 1s ease-out;
      z-index: 1;
    }
  }

  &__controls {
    color: $c-white;
    display: none;
    position: absolute;
    right: 0;
    bottom: 30px;

    @include breakpoint($bp-medium) {
      display: block;
    }

    > * {
      margin-left: 10px;
      cursor: pointer;

      &.current-button {
        color: $c-brand-green;
      }
    }
  }
}

.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.button {
    text-transform: uppercase;
    padding: 10px 20px;
    font-size: 12px;
    cursor: pointer;
    user-select: none;
    letter-spacing: 3px;
    min-width: 120px;
    text-align: center;
    transition: 0.3s;

    * {
        color: $c-brand-blue--light;
    }

    .hero__content & {
        color: $c-white;
        border: 1px solid $c-white;
    }

    .section--light & {
        color: $c-brand-blue--light;
        border: 1px solid $c-brand-blue--light;

        a,
        a:visited {
            color: $c-brand-blue--light;
        }
    }

    .section--dark & {
        color: $c-white;
        border: 1px solid $c-white;

        a,
        a:visited {
            color: $c-white;
        }
    }

    .hero__bottom &--scroll {
        color: $c-brand-green;
        border: 1px solid $c-brand-green;

        &:visited {
            color: $c-brand-green;
        }

        &:hover {
            color: $c-white;
            border-color: $c-white;
        }

        .hero--slideshow--padded & {
            color: $c-white;
            border: 1px solid $c-white;

            &:hover {
                color: $c-brand-green;
                border-color: $c-brand-green;
            }
        }
    }

    &:hover {
        border-color: $c-brand-green;
        > * {
            color: $c-brand-green;
        }

        a,
        a:visited {
            color: $c-brand-green;
        }
        color: $c-brand-green;
    }
}

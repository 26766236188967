.latest-news {
  overflow: hidden;
  position: relative;

  &__head {
    display: flex;
    margin-bottom: 45px;
    margin-right: 30px;
    flex-direction: column;

    @include breakpoint($bp-medium) {
      flex-direction: row;
      justify-content: space-between;
    }

    @include breakpoint($bp-xxl) {
      margin-right: 0;
    }

    > * {
      margin-right: 40px;
      display: flex;
    }

    .header {
      > * {
        margin-bottom: 20px;
      }

      flex-direction: column;
      align-items: flex-start;

      @include breakpoint($bp-medium) {
        flex-direction: row;
        > * {
          margin-right: 40px;
          margin-bottom: 0;
        }
      }
    }

    .control {
      color: $c-brand-blue--light;
      cursor: pointer;
      font-size: 1.5em;

      &__wrapper {
        display: flex;
        margin-right: 0;

        > * {
          margin-top: 20px;
          margin-left: 0px;
        }

        @include breakpoint($bp-medium) {
          margin-right: 60px;
          > * {
            margin-top: 0;
            margin-left: 20px;
          }
        }

        @include breakpoint($bp-large) {
          margin-right: 50px;
        }
      }

      &:first-child {
        margin-right: 22px;
      }

      &__wrapper {
      }
    }
  }
}

.carousel {
  &__content {
    padding-right: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    > * {
      margin-bottom: 20px;
    }

    .ie & {
      > * {
        width: 100%;
      }
    }

    p {
      font-size: 0.9375em;
    }

    .image {
      transition: 0.3s;
    }

    .image__wrapper {
      padding-bottom: 100%;
      margin-bottom: 40px;

      @include breakpoint($bp-medium) {
      }
    }

    .date {
      text-transform: uppercase;
      color: $c-brand-blue--light;
    }
  }

  &__item {
    position: relative;
    max-width: 100vw;

    @include breakpoint($bp-large) {
    }

    &:hover {
      .button {
        color: $c-brand-green;
        border-color: $c-brand-green;
      }

      .image {
        transform: scale(1.1);
      }
    }
  }
}

.square-image-with-text {
  .section__container {
    @supports (display: grid) {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto auto;
    }

    align-items: center;

    .ie & {
      display: flex;
      width: 100%;
      align-items: center;
    }

    @include breakpoint($bp-medium) {
      @supports (display: grid) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
      }

      &.flip-layout {
        @supports (display: grid) {
          grid-template-columns: 1fr 1fr;
          justify-content: flex-end;
        }

        .ie & {
          justify-content: flex-end;
        }

        &.layout--centre {
          justify-content: center;
        }
      }

      &.layout--centre {
        justify-content: center;
      }
    }

    @include breakpoint($bp-xxl) {
      @supports (display: grid) {
        grid-template-columns: 33% 660px;
        grid-template-rows: 1fr;
      }

      &.flip-layout {
        @supports (display: grid) {
          grid-template-columns: 660px 33%;
        }

        justify-content: flex-end;

        .ie & {
          justify-content: flex-end;
        }

        &.layout--centre {
          justify-content: center;
        }
      }

      &.layout--centre {
        justify-content: center;
      }
    }

    &.flip-layout {
      .image__wrapper {
        @supports (display: grid) {
          grid-column: 1;
          // grid-row: 2;
        }

        .ie & {
          order: 2;
        }

        @include breakpoint($bp-medium) {
          @supports (display: grid) {
            grid-row: 1;
            grid-column: 2;
          }
        }
      }

      .content {
        @supports (display: grid) {
          grid-column: 1;
        }
        padding-left: 20px;
        margin-top: 0;

        margin-bottom: 40px;

        > * {
          .ie & {
            width: 100%;
          }
        }

        .ie & {
          order: 1;
          width: 470px;
          max-width: 470px;
          padding: 0;
          padding-right: 50px;
        }

        @include breakpoint($bp-medium) {
          padding-right: calc(min(7.81vw, 150px));
          padding-left: 20px;
        }

        @include breakpoint($bp-xl) {
          padding-right: 200px;
        }
      }
    }
  }

  .image__wrapper {
    @supports (display: grid) {
      grid-column: 1;
      grid-row: 1;
    }
    padding-bottom: 100%;
    max-height: 529.86px;
    width: 100%;

    .ie & {
      padding-bottom: 0;
      width: 530px;
      height: 530px;
    }

    @include breakpoint($bp-medium) {
      @supports (display: grid) {
        grid-row: 1;
      }
    }

    @include breakpoint($bp-xxl) {
      max-width: 529.86px;
    }
  }

  .content {
    @supports (display: grid) {
      grid-column: 1;
    }

    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 50px;

    > * {
      .ie & {
        width: 100%;
      }
    }

    .ie & {
      width: 470px;
      padding-left: 150px;
    }

    @include breakpoint($bp-medium) {
      @supports (display: grid) {
        grid-row: 1;
        grid-column: 2;
      }
      margin-top: 0;
      padding-left: 20px;
    }

    > * {
      margin: 15px 0;

      @include breakpoint($bp-medium) {
        margin: 22px 0;
      }
    }

    p {
      width: 100%;

      @include breakpoint($bp-medium) {
        width: 90%;
      }

      .ie & {
        width: 100%;
      }
    }
  }
}

.search-page {
    padding-top: calc(min(5.2vw, 100px));
}

.search-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $c-white;
    margin-top: 70px;
    margin-bottom: 150px;

    h1 {
        border: none;
        text-align: center;
    }
}

.search-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

    @include breakpoint($bp-small) {
        flex-direction: row;
        align-items: center;
        align-items: start;
    }

    input {
        width: 100%;
        padding: 20px;
        font-size: 1rem;
        border-style: none;
        border: 1px solid $c-brand-blue--light;
        color: $c-text;
        margin-bottom: 10px;

        &::placeholder {
            color: $c-brand-blue--light;
        }
    }

    button {
        text-transform: uppercase;
        transition: 0.4s;
        font-size: 0.975em;
        letter-spacing: 2px;
        border: none;
        background-color: $c-brand-blue;
        padding: 0 20px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        color: $c-white;
        margin: 0;

        @include breakpoint($bp-small) {
            margin-left: 20px;
            margin-bottom: 10px;
        }

        &:hover {
            border-bottom: none;
        }
    }
}

.no-results {
    display: flex;
    flex-direction: column;
    padding-bottom: 500px;
}

.not-found {
    display: grid;

    > * {
        grid-column: 2;
    }
}

.search-query {
    margin: 70px 0;
}

.search-page {
    &__repeater {
        display: flex;
        flex-direction: column;

        &-item {
            display: flex;
            flex-direction: column;
            padding-top: 25px;
            margin-bottom: 25px;
            border-top: 1px solid $c-brand-blue--light;

            @include breakpoint($bp-medium) {
                flex-direction: row;
            }

            .content {
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: flex-start;

                > * {
                    margin-bottom: 10px;
                }

                a.header-link {
                    border-bottom: none;
                }

                .category {
                    text-transform: uppercase;
                }
            }

            h3 {
                margin-top: 0;
            }

            .image__wrapper {
                width: 100%;
                height: 200px;
                min-height: 0;
                margin-right: 50px;
                margin-bottom: 25px;

                @include breakpoint($bp-medium) {
                    max-width: 200px;
                    margin-bottom: 0;
                }
            }
        }
    }
}

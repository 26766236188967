.latest-news-header {
    position: relative;
    padding-top: calc(min(5.2vw, 100px));
    margin-bottom: 100px;

    @include breakpoint($bp-medium) {
        margin-bottom: 0;
    }

    .latest-news-slide {
        position: absolute;
        top: 0;
        left: 0;
        padding-top: inherit;
        opacity: 0;
        visibility: hidden;
        transition: 0.4s;
        display: grid;
        column-gap: 60px;
        align-items: center;

        .ie & {
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding: 0 20px;
        }

        @include breakpoint($bp-xl) {
            grid-template-columns: repeat(4, 1fr);
        }

        .content {
            .ie & {
                width: 20%;
                height: 400px;
            }
        }

        .subject__content {
            width: 100%;

            @include breakpoint($bp-medium) {
                width: 50%;
            }

            @include breakpoint($bp-xl) {
                width: 100%;
            }
        }

        p {
            font-size: 0.9375em;
        }

        &--1 {
            position: relative;
            padding-top: 0;
        }

        &.current-slide {
            opacity: 1;
            visibility: visible;
        }

        .posted-date {
            color: $c-brand-blue--light;
        }

        .image__wrapper {
            grid-column: 2 / -1;
            padding-bottom: 58.5%;
            grid-row: 1;

            .ie & {
                width: 65%;
                height: 600px;
                padding-bottom: 0;
            }
        }
    }

    .control {
        color: $c-brand-blue--light;
        cursor: pointer;
        font-size: 1.875em;

        &:first-child {
            margin-right: 22px;
        }

        &__wrapper {
            display: flex;
            margin-top: 50px;
        }
    }
}

.archive__header {
    padding-top: calc(min(5.2vw, 100px));

    .categories {
        padding: 35px 50px;
        margin-top: 35px;
        border-left: 1px solid $c-brand-green;
        display: flex;
        flex-wrap: wrap;

        li {
            margin-right: 18px;
            text-transform: uppercase;

            &.current-cat a {
                color: $c-brand-green;
            }

            &:hover {
                a {
                    color: $c-brand-green;
                }
            }
        }
    }
}

.image {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform-origin: center center;

    &__wrapper {
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: relative;

        a.overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            cursor: pointer;
            opacity: 0;
            transition: 0.4s;
            z-index: 10;

            &:hover + picture > * {
                transform-origin: center;
                transform: scale(1.04);
                transition: 1s;
            }
        }
    }
}

.gallery {
    padding-top: calc(min(5.2vw, 100px));
    &__repeater {
        display: grid;
        gap: 20px;
        grid-template-columns: 1fr;

        .ie & {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        @include breakpoint($bp-medium) {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    &__item {
        cursor: pointer;
        transition: 0.5s;
        position: relative;
        height: auto;
        width: auto;

        .ie & {
            width: 32%;
            margin-bottom: 30px;
        }

        .image__wrapper {
            padding-bottom: 100%;

            .image {
                transition: 0.5s;
            }

            &:hover {
                .image {
                    transform: scale(1.05);
                }
            }
        }

        &--open {
            position: fixed;
            z-index: 1000;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba($c-black, 0.6);

            .image__wrapper {
                padding-bottom: 0;
                position: relative;
                width: auto;
                height: auto;

                .image {
                    position: relative;
                }

                &:hover {
                    .image {
                        transform: none;
                    }
                }
            }
        }

        .image__caption {
            position: absolute;
            bottom: 35px;
            left: 35px;
            color: $c-white;
        }

        .overlay {
            background-image: linear-gradient(
                rgba(#161615, 0) 0,
                rgba(#161615, 0) 30%,
                rgba(#161615, 0) 40%,
                rgba(#161615, 0.9) 100%
            );
        }
    }

    &__intro {
        margin-bottom: 30px;

        @include breakpoint($bp-large) {
            width: 60%;
        }

        @include breakpoint($bp-xl) {
            width: 45%;
        }
    }
}

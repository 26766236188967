.protected-post-form {
    display: flex;
    flex-direction: column;

    p {
        color: $c-white;
        display: flex;
        margin-bottom: 30px;
        flex-direction: column;
        align-items: center;
        text-align: center;

        @include breakpoint($bp-medium) {
            flex-direction: row;
        }

        &:first-of-type {
            font-size: 1.5em;
            text-transform: uppercase;
        }
    }

    label {
        color: $c-white;
    }

    input[type="password"] {
        margin: 10px 10px;
        border: 2px solid $c-brand-blue;

        &:focus {
            outline: none;
            border: 2px solid $c-brand-green;
        }
    }

    &__wrapper {
        position: relative;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        z-index: 1000;
        background-color: $c-brand-blue;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .logo {
            margin-bottom: 20px;
        }
    }
}

.post__password {
    background: #ffffff;
    border: 1px solid #999;
    color: #333333;
    padding: 10px;
}

.password__submit {
    background-color: transparent;
    color: $c-white;
    padding: 10px 20px;
    border: 1px solid $c-white;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
        color: $c-brand-green;
        border: 1px solid $c-brand-green;
    }
}

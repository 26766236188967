.wsp-pages-title {
    display: none;
}

.wsp-pages-list {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 25px;
    margin: 50px 0;

    grid-template-columns: 1fr;

    * {
        list-style: none;
        border-bottom: none;
        font-size: 16px;

        :hover {
            border-bottom: none;
        }
    }

    li {
        margin: 10px 0;

        &:hover > a {
            color: $c-brand-green;
        }
    }

    > .page_item > a {
        font-size: 25px;
        text-transform: uppercase;
        padding-bottom: 10px;
        color: $c-brand-blue--light;
    }

    > .page_item_has_children {
        > .children {
            display: flex;
            flex-direction: column;
            margin-top: 20px;

            li {
                margin-bottom: 20px;
            }

            > .page_item a {
                font-size: 18px;
                margin-bottom: 15px;
                text-transform: uppercase;
            }

            > .page_item_has_children {
                order: 10;
                width: 100%;

                > a {
                    padding-bottom: 10px;
                    padding-right: 0;
                    border-left: none;
                }

                > .children {
                    display: flex;
                    flex-wrap: wrap;
                    margin-top: 20px;

                    > .page_item a {
                        font-size: 15px;
                        margin-bottom: 15px;
                        text-transform: none;
                        margin-right: 15px;
                        padding-left: 15px;
                        border-left: 1px solid $c-brand-green;
                    }
                }
            }
        }
    }
}
